.problem{
    width: 90%;
    margin: 5% 5%;
}

/* Initial state: title is off-screen below */
.problem__title {
    margin-top: 10vh;
    color: #001F3D;
    text-align: start;
    width: 100%;
    white-space: pre-wrap;
    opacity: 0;
    transform: translateY(100px); /* Start 100px below its normal position */
    transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 0.3s ease; /* Smooth transition */
}

/* When the title is in its final position */
.problem__title_start {
    padding-bottom: 0;
    margin-bottom: 0;
    text-align: start;
    line-height: 54px;
    font-size: 44px;
    opacity: 1;
    transform: translateY(0); /* Move to its normal position */
}

.problem__title_end {
    line-height: 54px;
    font-size: 44px;
    text-align: end;
}

/* Container for cards */
.problem__container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 80px;
    margin-bottom: 120px;
    /* overflow: hidden;  */
}

/* Initial state: cards are off to the left */
.problem__card {
    width: 16vw;
    padding-left: 1vw;
    padding-right: 1vw;
    opacity: 0;
    /* transform: translateX(-150px); */
     /* Start off 150px to the left */
    transform: translateY(100px);
    transition: transform 0.6s ease-out, opacity 0.6s ease-out; /* Slower animation duration */
    background-color: #F5F5F5;
    border-radius: 10px;
    }

/* Visible state: cards slide in one by one */
.problem__card.visible {
    opacity: 1;
    /* transform: translateX(0);  */
    /* Slide into their normal position */
    transform: translateY(0);
}

/* Staggered animation delays for each card */
.problem__card:nth-child(1) {
    transition-delay: 0.3s;
}

.problem__card:nth-child(2) {
    transition-delay: 0.6s;
}

.problem__card:nth-child(3) {
    transition-delay: 0.9s;
}

.problem__card:nth-child(4) {
    transition-delay: 1.2s;
}

/* Description styles */
.problem__description {
    color: #001F3D;
    white-space: pre-wrap;
    font-size: 18px;
}

.problem_description_bold{
    font-weight: bold;
}

/* Ecology section */
.problem__ecology-container {
    display: flex;
    flex-direction: row;
    flex-direction: flex-start;
    width: 100%;
}

.problem__ecology-link {
    text-decoration: none;
}

.problem__ecology {
    width: 100%;
    padding: 10px;
    background-image: url(../../images/ecology/grass.jpg);
    background-size: cover;
    background-position: left;
    object-fit: contain;
    font-size: 20px;
    line-height: 1.5;
    font-weight: bold;
    text-align: center;
    height: 100%;
    cursor: pointer;
}

.problem__ecology:hover {
    opacity: 0.5;
}

.problem__ecology-text {
    white-space: pre-wrap;
    color: white;
    text-decoration: none;
    padding: 0;
    margin: 0;
}

/* Media Queries for responsiveness */
@media screen and (max-width: 1100px) {
    .problem {
        margin: 70px auto;
    }

    .problem__title {
        width: 100%;
        margin-top: 20px;
    }

    .problem__title_start {
        text-align: start;
        font-size: 30px;
        line-height: 36px;
    }

    .problem__title_end {
        text-align: start;
        font-size: 28px;
        line-height: 34px;
    }

    .problem__container {
        width: 90%;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 40px auto 0;
    }

    .problem__card.visible {
        margin-top: 20px;
    }

    .problem__card {
        width: 45%;
        text-align: center;
    }

    .problem__description {
        font-size: 16px;
    }

    .problem__ecology-container {
        margin-top: 50px;
    }

    .problem__ecology {
        font-size: 16px;
    }
}

@media screen and (max-width: 450px) {
    .problem__title {
        width: 100%;
        font-size: 30px;
        line-height: 36px;
        text-align: center;
    }

    .problem__container {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .problem__card {
        width: 100%;
        text-align: center;
        margin: 5% auto;
    }

    .problem__description {
        font-size: 16px;
    }

    .problem__ecology {
        border-radius: 12px;
    }

    .problem__title {
        transition: opacity 0.6s ease, transform 0.6s ease;/* Smooth transition */
    }
    .problem__card {
        transition: transform 0.6s ease-out, opacity 0.6s ease-out; /* Slower animation duration */
        }
    
    /* Staggered animation delays for each card */
    .problem__card:nth-child(1) {
        transition-delay: 0s;
    }

    .problem__card:nth-child(2) {
        transition-delay: 0s;
    }

    .problem__card:nth-child(3) {
        transition-delay: 0s;
    }

    .problem__card:nth-child(4) {
        transition-delay: 0s;
    }
}
