.market{
    /* height: 70vh; */
    background-color: #001F3D;
    width: 72%;
    padding: 0% 14% 5%;
}


.market__title{
    text-align: start;
    color: white;
    width: 50%;
    line-height: 54px;
    font-size: 44px;
    /* border-bottom: 4px white solid; */
    margin:0;
    margin-bottom: 20px;
}

.market__container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.market__link-insights{
    text-decoration: none;
}

@media screen and (max-width: 768px) {
    .market{
        /* height: 80vh; */
        width: 100%;
        padding: 0% 0% 70px; 
    }
    
    .market__title{
        /* width: 80%; */
        width: 90%;
        margin: 0 auto 20px;
    }

    .market__container {
        margin: 0 5%;
    }

    .market__subtitle{
        padding: 0 5%;  
    }
}

@media screen and (max-width: 450px) {
    .market__title{
        /* width: 100%; */
        font-size: 30px;
        line-height: 36px;
        text-align: center;
    }

    .market__container{
        margin-left: 5%;
        margin-right: 0; 
        padding: 0;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        -ms-verrflow-style: none;
        overflow: - moz-scrollbars-none;
        /* box-shadow: 0px 0px 80px #f3c9ff2e; */
        background-color: #001F3D;
        scrollbar-width: none; 
        -ms-overflow-style: none; 
    }

    .market__container::-webkit-scrollbar{
        width:0;
        display: none;
    }
}