.cobalt__topic{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #001F3D;
    height: 100%;
    text-align: start;
    /* margin-top: 10vh; */
    /* margin-bottom: 5vh; */
}

.cobalt__title{
    color: white;
    width: 55%;
    margin: 10vh 0 0 5vw;
    /* padding: 5vh 0 0; */
    line-height: 60px;
    font-size: 40px;
    white-space: pre-wrap;
}

.cobalt__image{
    width: 45%;
    height: 90vh;
    margin-top: 10vh;
    object-fit: cover;
}

.cobalt__subtitle{
    color: white;
    width: 60%;
    margin-left: 5vw;
    /* margin-bottom: 5vh; */
    line-height: 40px;
    margin-top: 20px;
    font-size: 38px;
}

.cobalt__intro{
    margin-top: 20px;
    text-align: start;
    color: #001F3D;
    width: 90%;
    margin: 0 auto;
 }
 
 .cobalt__description{
    text-align: start;
    color: #001F3D;
    width: 90%;
    margin: 0 auto;
    margin-top:10px;
 }

.cobalt__container{
    display: flex;
    margin: 0 10vw;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media screen and (max-width: 1024px) {
    /* .cobalt__title{ */
        /* width: 80%; */
        /* width: 90%;
        margin: 0 auto 4vh;
    } */

    .cobalt__container {
        /* height: 100%; */
        padding: 5% 0%;
        margin: 0 5%;
    }
}

@media screen and (max-width: 768px) {
    /* .cobalt__topic{
        justify-content: center;
        padding-left: 5%;
        padding-top: 65px;
        height: 195px;
    } */
    
    .cobalt__topic{
        /* display:flex; */
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 65px;
        /* background-color: #001F3D;
        height: 100%;
        text-align: start; */
    }

    .cobalt__title{
        color: white;
        width: 90%;
        margin: 40px auto 20px;
        /* padding: 5vh 0 0; */
        line-height:48px;
        font-size: 36px;
        white-space: pre-wrap;
    }
    
    .cobalt__image{
        width: 100%;
        height: 350px;
        margin-top: 0;
        object-fit: cover;
    }
    
    
    /* .cobalt__title{
        margin: 0;
        width: 80%;
    } */

    .cobalt__subtitle{
        margin: 12px 0 0;  
    }
}

@media screen and (max-width: 650px) {
    /* .cobalt__topic{
        justify-content: center;
        align-items: center;
        padding-left: 0;
    } */

    .cobalt__title{
        width: 90%;
        /* margin: 0 auto;
        font-size: 2em; */
        font-size: 30px;
        text-align: center;
        margin-top: 20px;
    }
    
    .cobalt__subtitle{
        width: 90%;
        margin: 12px auto auto;  
        font-size: 2em;
        text-align: center;
    }

    .cobalt__container{
        margin-top: 20px;
        width: 95%;
        margin-left: 5%;
        padding: 0;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        -ms-verrflow-style: none;
        overflow: - moz-scrollbars-none;
        flex-wrap: nowrap;
        scrollbar-width: none;
        -ms-overflow-style: none; 
    }
    .cobalt__container::-webkit-scrollbar{
        width:0;
    }
}