.warehouse{
   /* margin-top: 5vh; */
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
   /* height: 60vh; */
   margin-top: 40px;
   /* width: 90%; */
}

.warehouse__title{
   text-align: center;
   color: #001F3D;
   width: 80%;
   margin: 0 auto 20px;
   font-size: 24px;
}

.warehouse__description{
   text-align: start;
   color: #001F3D;
   width: 75%;
   margin: 0 auto;
   margin-top:10px;
   margin-bottom: 20px;
}

.warehouse__photo{
   background-image: url(../../images/offer_copper.jpg);
   width: 80%;
   margin: 0 auto;
   height: 40vw;
   margin-bottom: 100px;
}

.warehouse__map-container{
     width: 1000px;
     height: 500px;
     margin-bottom: 100px;
     border: 4px solid #001F3D;
} 
  .warehouse__map{
     position: relative;
     margin-bottom: 50px;
     overflow: hidden;
  }

.warehouse__tooltip{
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 1;
  background-color: #001F3D;
  color: white;
  /* border: 5px solid white; */
  border-radius: 10px;
  padding: 5px;
  height: 40px;
  width: 80px;
  text-align: center;
  text-justify: center;
}

.warehouse__text{
  position: absolute;
  top: 40%;
  left: 5%;
  width: 30%;
  font-size: 20px;
  font-weight: bold;
  color: #001F3D;
  white-space: pre-wrap;
}

@media screen and (max-width: 1000px) {
  .warehouse__map-container{
     width: 600px;
     height: 500px;
     margin-bottom: 100px;
     border: 4px solid #001F3D;
  } 

  .warehouse__text{
     position: absolute;
     top: 40%;
     left: 0%;
     width: 30%;
     font-size: 20px;
   }
}

@media screen and (max-width: 768px) {
  .warehouse{
      height: 100%;
      margin-bottom: 50px;
  }
  .warehouse__photo{
     height: 30vw;
  }

  .warehouse__text{
     position: absolute;
     top: 50%;
     left: 0%;
     width: 30%;
     font-size: 16px;
   }

  .warehouse__map-container{
     width: 450px;
     height: 400px;
     margin-bottom: 50px;
} 
}

@media screen and (max-width: 450px) {
  .warehouse__map-container{
     width: 350px;
     height: 300px;
     margin-bottom: 50px;
} 

  .warehouse__text{
     position: absolute;
     top: 45%;
     left: 30%;
     width: 35%;
     font-size: 14px;
  }
}

@media screen and (max-width: 350px) {
  .warehouse__map-container{
     width: 250px;
     height: 320px;
     margin-bottom: 50px;
} 
.warehouse__text{
     position: absolute;
     top: 45%;
     left: 15%;
     width: 50%;
     font-size: 14px;
}
}