.footer{
    background-color: #001F3D;
    display: flex;
    flex-direction: column;
}

.footer__info{
    display: flex;
    flex-direction:row;
    width: 80%; 
    margin: 0 auto;
    justify-content: space-between;
    align-items: flex-start;
    height: 140px;
    padding-top: 50px;
}

.footer__link{
    color: white;
    text-decoration: none;
}

.footer__link:hover {
    color: #646060;
}

.footer__outline{
    color: white;
    font-weight: bold;
}
.footer__link_title{
    font-weight: bold;
}

.footer__link_subtitle{
    margin-top: 5px;
}


.footer__list{
    margin:0;
    padding: 0;
    color: white;
    text-decoration: none;
    list-style-type: none;
    text-align: start;
}

.footer__list-item{
    margin: 7px auto;
}

.footer__subtitle{
    color: white;
    text-decoration: none;
    margin-top: 10px;
    font-weight: normal;
}

.footer__reg{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    color: white;
    width: 80%;
    height: 80px;
    font-size: 14px;
}

.footer__reg-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.footer__reg-link{
    color: white;
    text-decoration: none;
    margin-left: 30px;
    font-weight: bold;
}

.footer__reg-link:hover{
    color: #646060;
}

.footer__reg-logo{
 width: 35px;
 height: 35px;  
 cursor: pointer; 
}

.footer__reg-logo_x{
    margin-left: 17.5px;
}

@media screen and (max-width: 1000px) {
.footer__info{
 width: 90%;
}

.footer__reg{
    width: 90%;
}
} 

@media screen and (max-width: 450px) {
    .footer__info {
        height: 500px;
        padding: 0 5%;
        flex-direction:column;
        justify-content: space-evenly;
        align-items: flex-start;
    }

    .footer__reg {
        height: 200px;
        padding: 0 5%;
        flex-direction:column;
        justify-content: space-evenly;
        align-items: flex-start;
        margin: 0;
    }
    
    .footer__reg-container {
        flex-direction:column;
        justify-content: space-between;
        align-items: flex-start;
    }

    .footer__reg-link{
        margin-left: 0;
        margin-bottom: 10px;
    }
}