.offer__topic{
    position: fixed;
    display:flex;
    flex-direction: column;
    justify-content: end;
    align-items: start;
    background-color: #001F3D;
    height: 210px;
    text-align: start;
    z-index: 1;
}


.offer__title{
    color: white;
    width: 90%;
    margin-left: 5vw;
    margin-bottom: 25px;
    line-height: 38px;
    font-size: 30px;
}
/* .off{
    position: relative;
    top: 280px;
} */
.offer__container{
    position:relative;
    padding-top: 210px;
    /* height: 180vh; */
    /* z-index: 0; */
    margin-bottom: 150px;
}

.offer__card{
    position: sticky;
    /* height: 40vh; */
    margin: 0 20vw;
    border-bottom: 4px solid #001F3D;
    /* We should specify a point when a card should be sticky */
    top: 250px;
    /* transform-origin: center top; */
    background-color: #fff;
    margin-bottom: 40px;
}

.offer__card-image{
    height: 150px;
    width: 100%;
    background-image: url(../../images/offer_copper.jpg);
    background-size:cover;
}

.offer__card-title{
    text-align: start;
    color:#001F3D;
    background-color: #fff;
}

.offer__card-description{
    text-align: start;
    color:#001F3D;
    background-color: #fff;
    height: 60px;
}

@media screen and (max-width: 900px) {
    .offer__topic{
        justify-content: center;
        padding-left: 5%;
        padding-top: 70px;
        height: 195px;
    }
    
    .offer__title{
        margin: 0;
        width: 100%;
        font-size: 24px;
    }

    .offer__card{
        font-size: 20px;
        margin: 30px 10vw 100px;
        top: 280px;
    }

    .offer__card-image{
        height: 100px;
    }

    .offer__card-title{
        font-size: 24px;
    }
    
    .offer__card-description{
        font-size: 18px;
        margin-bottom: 50px;
    }
    
    .offer__container{
        margin-bottom: 287px;
    }
}

@media screen and (max-width: 650px) {
    .offer__topic{
        justify-content: center;
        align-items: center;
        padding-left: 0;
        padding-top: 65px;
        height: 195px;
    }
    
    .offer__title{
        margin: 0;
        width: 90%;
        margin: 0 auto;
        font-size: 20px;
        text-align: start;
    }

    .offer__card{
        font-size: 20px;
    }
    
    .offer__card-title{
        font-size: 20px;
    }

    .offer__card-description{
        font-size: 16px;
        margin-bottom: 100px;
    }
    .offer__container{
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 400px) {
    .offer__title{
        font-size: 1.2em;
        /* font-weight: bold; */
    }
}