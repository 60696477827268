.product{
    width: 80%;
    margin: 5% auto; 
}

.product__title{
    color: #001F3D;
    text-align: start;
    width: 100%;
    text-wrap: nowrap;
    /* border-bottom: #001F3D 4px solid; */
    line-height: 54px;
    font-size: 44px;
    text-wrap: wrap;
    white-space: pre-wrap;
    margin-bottom: 50px;
}

.product__container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #001F3D;
}

.product__card{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 18vw;
    color: #001F3D;
}

.product__subtitle{
    margin: 0;
    line-height: 30px;
    margin-bottom: 10px;
    height: 60px;
}

.product__image-content{
    position: relative;
    width: 18vw;
    height: 20vw;
    overflow: hidden;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}


.product__image{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* margin-bottom: 16px; */
}

/* .product__image_stockage{
    object-fit: 0;
} */

/* .product__image:hover{
    transform: scale(1.1);
} */

.product__description{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    bottom: 0;
    left: 0;
    text-align: center;
    font-weight: bold;
    background-color: rgba(13, 14, 32, 0.77);
    height: 100%;
    transition: all 0.3s ease;
    /* cursor: pointer; */
    transform: translateY(100%);
    /* transition: 0.3s ease; */
    color: white;
    z-index: 0;
    padding: 0 5%;
}

.product__more-description{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    position: absolute;
    height: 10%;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: rgba(13, 14, 32, 0.77);
    color: #FAFAFA;
    white-space: pre-wrap;
    font-size: 14px;
}

.product__image-content:hover .product__description{
    transform: translateY(0%)
}

.product__image-content:hover .product__more-description{
    display: none;
}

@media screen and (max-width: 900px) {
    .product__container {
        flex-wrap: wrap;
        gap: 30px;
    }
    .product__title{
        line-height: 44px;
        font-size: 38px;
    }

    .product__card{
        width: 30vw;
    }
    .product__image-content{
        min-height: 240px;
        width: 100%;
    }

    .product__more-description{
        display: none;
    }
        
    .product__description_hover{
        transform: translateY(0%)  
    }
}

@media screen and (max-width: 500px) {
    .product{
        width: 90%
    }

    .product__title{
        font-size: 36px;
        line-height: 40px;
        text-align: center;
    }

    .product__container {
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
    }

    .product__card{
        width: 60vw;
    }
    .product__image-content{
        min-height: 280px;
    }

    .product__description{
        transition: all 0.6s ease;
    }
}