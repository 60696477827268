
.transition-graph{
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 40px;
    max-width: 100%;
    height: 100%;
    background-color: #F5F5F5;
    border-radius: 10px;
}

.transition-graph__tooltip{
    position: absolute;
    background-color: white;
    padding: 5px 2px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    border-radius: 10px;
    color:#001F3D;
    width: 157px;
    border: 2px #001F3D solid;
}

.transition-graph__graphics{
    display: inline-block;
    position: relative;
    width: 700px;
    height: 300px;
    resize: both;
    object-fit: cover;
    font-size: 50px;
    /* margin-top: 20px; */
}

.transition-graph__graphics_inside{
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.transition-graph__title{
    color: #001F3D;
    /* color: #FF6B6B; */
    margin-bottom: 0;
    font-weight: bold;
    font-size: 20px;
    margin-top: 15px;
    margin-bottom: 5px;
    white-space: pre-wrap;
}

.transition-graph__buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 70%;
    background-color: inherit;
    margin-bottom: 20px;
    margin-top: 20px;
}

.transition-graph__line {
    fill: none;
    /* stroke: white; */
    stroke-width: 1;
}

.transition-graph__axis{
    color: #001F3D;
    font-family: inherit;
    font-size: 14px;
}

.transition-graph__source{
    width: 700px;
    text-align: center;
    /* color: white; */
    font-size: 12px;
    color:#001F3D;

    font-style: italic;
    /* padding-left: 40px; */
}

.transition-graph__grid{
    color: #001F3D;
    font-family: inherit;
    font-size: 14px;
}

.transition-graph__legend-button{
    position: relative;
    text-decoration: none;
    background-color: inherit;
    border-radius: 90px;
    width: 20px;
    height: 20px;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
}


.transition-graph__background{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    top: -100px;
    left:-46vw;
    background-color: rgba(0,0,0,0.95);
    width: 46vw;
    height: 200%;
    z-index: 2;
    cursor: pointer;    
}

.transition-graph__legend{
    color:#001F3D;
    background-color: #fff;
}

.overlay-circle {
    -webkit-tap-highlight-color: #F5F5F5; /* Set the tap highlight color */
  }
    


@media screen and (max-width: 1300px) {
    .transition-graph{
        margin-right: 0;    
    }

    .transition-graph__graphics{
        width: 500px;
        font-size: 40px;
    }
    
    .transition-graph__source{
        width: 500px;
    }

    .transition-graph__buttons{
        width: 90%;
        /* margin-left: 5%; */
    }

    
    .transition-graph__background{
        left:-55vw;
        width: 52vw;
    }
}

@media screen and (max-width: 1100px) {
    .transition-graph__background{
        left:-47vw;
        width: 47vw;
        }
    }
@media screen and (max-width: 1000px) {
    .transition-graph{
        margin-right: 0;
        max-width: 100%;
        font-size: 30px;
        max-width: 100%;
    }

    .transition-graph__graphics{
        width: 500px;
        height: 300px;
        resize: both;
    }
    
    .transition-graph__buttons{
        /* justify-content: flex-start; */
        width: 80%;
    }

    .transition-graph__source{
        width: 400px;
    }
    .transition-graph__background{
        left: -40vw;
        width: 40vw;
        }
}

@media screen and (max-width: 900px) {
    .transition-graph__graphics{
        width: 700px;
        height: 300px;
        resize: both;
    }

    .transition-graph__buttons{
        width: 100%;
        justify-content: space-evenly;
    }

    .transition-graph__source{
        width: 700px;
    }
    .transition-graph__background{
        position: relative;
        background-color: inherit;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; 
        border-left: 2px white solid;
    }
}

@media screen and (max-width: 750px) {
    .transition-graph__graphics{
        width: 500px;
        height: 300px;
        resize: both;
    }
    
    .transition-graph__source{
        width: 100%; 
        text-align: center;
        padding-left: 0;
    }
}

@media screen and (max-width: 500px) {
    .transition-graph__graphics{
        width: 380px;
        height: 340px;
    }
    .transition-graph__legends{
        flex-direction: column;
    }
    .transition-graph__legend{
        padding-left: 3%;
        display: list-item;
        list-style-type: square;
        list-style-position: inside;
        width: 100%;
        text-align: start;
    }
}

@media screen and (max-width: 420px) {
    .transition-graph__graphics{
        width: 350px;
    }
    .transition-graph__buttons{
        width: 100%;
    }
    .transition-graph__legend{
        padding-left: 2%;
    }
}

@media screen and (max-width: 320px) {
    .transition-graph__graphics{
        width: 300px;
    }
    .transition-graph__legend{
        padding-left: 1%;
        font-size: 12px;
    }

    .transition-graph__legend-button{
        width: 18px;
        height: 18px;
        font-size: 12px;
    }
    
}