.market__card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 20vw;
    height: 22vw;
}

.market__title{
    width: 90%;
    padding: 0 auto;
}

.market__image {
    width: 100%;
    height: 100%;
    opacity: 0.8;
    cursor: pointer; /* Change the cursor to indicate it's clickable */
    transition: all 0.3s ease; /* Smooth transition for hover effects */
    border-radius: 8px; /* Optional: add rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.6); 
}

/* Hover effect to make the image stand out more */
.market__image:hover {
    opacity: 1; /* Increase opacity on hover */
    transform: scale(1.05); /* Slightly enlarge the image */
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); /* Add a stronger shadow on hover */
}

/* Optional: active state to simulate pressing the button */
.market__image:active {
    transform: scale(0.98); /* Slightly shrink on click to simulate a press */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15); /* Reduce shadow on press */
}


/* .market__image_hover{
    opacity: 1;
} */

.market__card_copper:hover::before {
    opacity: 0.8;
}

.market__name{
    position: absolute;
    color: white;
    z-index: 2;
    opacity:1 ;
    white-space: pre-wrap;
    font-size: 34px;
}

.market__subtitle{
    text-decoration: none;
    border: none;
    background-color:  #001F3D;
    text-align: start;
    color: white;
    width: 100%;
    line-height: 32px;
    font-size: 22px;
    /* border-bottom: 4px white solid; */
    margin:0;
    margin-top: 4%;
    cursor: pointer;
    font-family: inherit;
    font-weight: bold;
    border-radius: 30px;
    /* text-decoration: underline; */
    height: 4vh;
    margin-bottom: 30px;
}

.market__subtitle:hover{
    color: #646060;
}

.market__link-insights{
    text-decoration: none;
}

@media screen and (max-width: 1300px) {
    .market__card{
        /* width: 22vw; */
        height: 24vw;
    }
}

@media screen and (max-width: 1000px) {
    .market__card{
        width: 22vw;
        height: 28vw;
    }
    
    .market__name{
        font-size: 28px;
    }
}

@media screen and (max-width: 900px) {
    .market__card{
        width: 22vw;
        height: 32vw;
    }
}


@media screen and (max-width: 768px) {
    .market__card{
        width: 28vw;
        height: 42vw;
        scroll-snap-align: start;
        cursor:'pointer'
    }

    .market__image{
        object-fit:cover;
        user-select: none;
        /* pointer-events: none; */
    }    
}

@media screen and (max-width: 450px) {
    .market__card{
        max-width: none;
        width: 70vw;
        height: 90vw;
        margin-right: 30px;
    }

    .market__subtitle{
        font-size: 16px;
    }

    .market__image{
        width: 70vw;
    }
}
