.solution{
    /* height: 90vh; */
    width:90%;
    margin: 0 5%;
    background-color: #001F3D;
    /* padding: 4vh 8vw; */
}

.solution__title{
    text-align: start;
    color: white;
    /* border-bottom: 4px solid white ; */
    line-height: 44px;
    font-size: 38px;
    margin-left: 4%;
    white-space: pre-wrap;
    margin:0;
    padding:0;
    padding-top: 4%;
    margin-left: 9%;
    width:90%;
    /* padding-left: 9%; */
}

.solution__container{
    display: flex;
    /* height: 50%; */
    flex-direction: row;
    justify-content: space-between;
    padding: 60px 9%;
}

.solution__list{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    list-style-type: none;
    margin:0;
    max-width: 450px;
    white-space: pre-wrap;
}

.solution__item{
    font-size: 24px;
    color: white;
    text-align: start;
    line-height: 40px;
    margin: 10px 0;
    width: 100%;
    border-left: white solid 6px;
    border-radius: 10px;
    padding-left: 2vw;
    white-space: pre-wrap;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}
    /* text-decoration: none; */
.solution__item_visible {
    opacity: 1;
    transform: translateY(0);
}

.solution__graph{
    width: 35vw;
    height: 25vw;
    color: white;
    box-shadow:#001F3D;
    margin-right: 0;
    background-image: url(../../images/copper_solution.webp);
    background-size: cover;
    /* background-position: right 50px top -40px; */
    transition:  0.2s ease-in-out;
    -webkit-transform: scale(0.96);
}

.solution__graph_hover{
    -webkit-transform: scale(1);
}

@media screen and (max-width: 1200px) {

    .solution__item{
    font-size: 20px;
    }
    .solution__graph{
        width: 35vw;
        height: 30vw;
}
}

@media screen and (max-width: 1000px) {
    .solution__item{
        font-size: 20px;
        width: 90%;
        }
    .solution__graph{
        width: 30vw;
        height: 350px;
}
}

@media screen and (max-width: 768px) {
    .solution__title{
        /* margin-left: 0; */
        width: 95%;
        font-size: 30px;
        line-height: 42px;
        padding:0;
        margin: 0 auto;
    }

    .solution__container{
        margin: 0 5%;
        padding: 0 0 60px;
        align-items: center;
    }

    .solution__item{
        font-size: 18px;
        line-height: 24px;
        margin-top: 12px;  
        border-left: white solid 10px; 
    }
    .solution__item{
        margin-top: 20px;
        width: 100%;
    }
    .solution__graph{
        align-self: flex-end;
        width: 30vw;
        height: 250px;
    }
}

@media screen and (max-width: 650px) {
    /* .solution{
        height: 90vh;
    } */
    
    .solution__title{
        padding-top: 20px;  
        font-size: 24px;
        line-height: 30px;
        text-align: center;
    }

    .solution__container{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 0;
        margin: 5% 0 0;
        height: 70%;
        padding-bottom: 0;
    }

    .solution__list{
        height: 35%;
        /* margin: 0 0; */
        margin-left: 5%;
        margin-bottom: 50px;
    }

    .solution__item{
        font-size: 18px;   
        margin-top: 8px;
        width:95%;
        white-space:unset;  
    }
    
    .solution__graph{
        width: 90vw;
        /* margin-top: 20px; */
        height: 50vw;
        /* background-position: right 0px top -40px; */
    }
}