.team{
    width: 72%;
    margin: 5% auto; 
}

.team__title{
    width: 100%;
    text-align: start;
    /* border-bottom: 4px solid #001F3D; */
    margin-bottom: 50px;
    color: #001F3D;
    line-height: 54px;
    font-size: 44px;
}

.team__container{
    margin: 0 4vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* overflow-x: scroll;
    scroll-snap-type: x mandatory;
    -ms-verrflow-style: none; */
}

.team__container::-webkit-scrollbar{
    width:0;
    display: none;
}

.team__card{
    width: 16vw;
    margin-right: 4vw;
    padding-bottom: 20px;
    border-bottom: 4px solid #001F3D;
}

.team__photo{
    width: 16vw;
    height: 20vw;
    object-fit: cover;
}

.team__name{
    color: #001F3D;
    width: 100%;
    font-size: 18px;
    margin-bottom: 4px;
}

.team__profession{
    color: #001F3D;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    white-space: pre-wrap;
}

.team__description{
    margin-top: 12px;
    word-wrap:break-word;
    color: #001F3D;
    white-space: pre-wrap;
    font-size: 15px;
}

@media screen and (max-width: 1000px) {
    .team{
        width: 90%;
        margin: 5% auto; 
    }
    
    .team__title{
        width: 80%;
    }

    .team__container {
        margin: 0;
        /* gap: 20px; */
    }
    
    .team__card{
                /* display: flex;
        flex-direction: column;
        justify-content: space-between; */
        width: 25vw;
        /* padding-bottom: 4vh; */
        border-bottom: 4px solid #001F3D;
        scroll-snap-align: start;
        pointer-events: none;
    }

    .team__photo{
        width: 25vw;
        height: 31.25vw;
        /* width:100%; */
        object-fit:cover;
        user-select: none;
        pointer-events: none;
    }    
}

@media screen and (max-width: 690px) {
    .team__name{
        height: 24px;
        font-size: 18px;
    }
}

@media screen and (max-width: 500px) {
    .team__title{
        width: 100%;
        font-size: 30px;
        line-height: 36px;
        text-align: center;
    }

    .team__container {
        margin: 0;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        -ms-verrflow-style: none;
        overflow: - moz-scrollbars-none;
        box-shadow: 0px 0px 80px #f3c9ff2e;
        scrollbar-width: none;
        -ms-overflow-style: none; 
    }
    
    .team__container::-webkit-scrollbar{
        width:0;
        display: none;
    }

    .team__description{
        font-size: 16px;
    }
    .team__card{
        width: 70vw;
    }

    .team__photo{
        width: 70vw;
        height: 87.5vw;
    }
}