.popup{
  position: absolute;
  top:0;
  left:0;
  bottom:0;
  right: 0;
  /* transition: opacity 2s ease; */
}

.popup__background{
  position: fixed;
  background-color: rgba(0,0,0,0.6);
  width: 100vw;
  height:100%;
  z-index: 2;
  cursor: pointer;
}

.popup__container{
  position: fixed;
  top: 20%;
  left: 35%;
  z-index: 3;
  width:30%;
  height: 60%;
}

.popup__content{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  background-color: #fff;
  width: 100%;
  height: 100%;
}

.popup__image{
    width: 100%;
    height: 40%;
    object-fit: cover;
}

/* .popup::before {
    position: absolute;
    content: "";
    background-color: #000000;
    height: 100%;
    width: 100%;
  } */

  .popup__title{
    color: #001F3D;
    margin-bottom: 0px;
  }

  .popup__text{
    color: #001F3D; 
    white-space: pre-wrap;
    text-align: start;
    width: 95%;
    margin: 10px auto 0;
  }
  
.popup__close-button {
    cursor: pointer;
    border: none;
    background: none;
    color: white;
    padding: 0;
    /* width: 32px;
    height: 32px; */
  }
  
  .popup__close-button:before {
    position: absolute;
    content: " ";
    height: 35px;
    border: 3px solid white;
    color: white;
    background-color: #fff;
    top: -35px;
    right: -24.75px;
    transform: rotate(45deg);
    z-index: 2;
  }
  
  .popup__close-button:after {
    position: absolute;
    content: " ";
    height: 35px;
    border: 3px solid white;
    color: white;
    background-color: #fff;
    top: -35px;
    right: -24.75px;
    transform: rotate(-45deg);
    z-index: 2;
  }
  
  .popup__close-button_hover::after{
    opacity: 0.8;
  }

  .popup__close-button_hover::before{
    opacity: 0.8;
  }
  
  @media (max-width: 1024px) {
    .popup__background{
      height:145vh;
    }

    /* .popup__close-button {
      width: 20px;
      height: 20px;
    } */

    .popup__container{
      width: 40%;
      /* top: 20%;
      left: 25%; */
    }

    .popup__close-button:before {
      height: 30px;
      top: -30px;
      right: -21.2px;
    }
    .popup__close-button:after {
      height: 30px;
      top: -30px;
      right: -21.2px;
    }
  }
  @media (max-width: 950px) {
    .popup__container{
      width: 60%;
      left: 20%;
    }
  }

  @media (max-width: 450px) {
    .popup__background{
      height: 180vh;
    }
    .popup__container{
      width: 80%;
      top: 20%;
      left: 10%;
    }

    .popup__text {
      font-size: 14px;
    }
  }
  