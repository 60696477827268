.front-article__graph-title{
    text-align: center;
    white-space: pre-wrap;
    margin-top: 30px;
    margin-bottom: 20px;
    font-weight: bold;
}

.front-article__pre-wrap{
    white-space: pre-wrap;
}

.front-article__graph-title-hint{
    display: none;
}

.front-article__paragraph{
    white-space: pre-wrap;
}

.front-article__span-bold{
    font-weight: bold;
}

.front-article__list{
    list-style-type: square;
}

.front-article__subtitle{
    text-align: center;
    white-space: pre-wrap;
    margin-top: 60px;
    margin-bottom: 20px;
}

.front-article__subsubtitle{
    text-align: center;
    font-size: 16px;
    margin-bottom: 0;
}

.front-article__source{
    font-size: 12px;
    font-style: italic;
    margin-top: 0;
    text-align: center;
    color:#001F3D;
    width: 100%;
    align-self: center;
    margin: 0 auto;
}

.front-article__link{
    color:#001F3D;
}
.front-article__image{
    width: 100%;
    height: 400px;
    margin: 30px auto 0;
}

.front-article__table{
    border-top: 1px solid #001F3D;
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    height: 300px;
    font-size: 14px;
}

.front-article__red{
    color: red;
    font-weight: bold;
}

.front-article__table-td{
    border-bottom:1px solid #001F3D;
    min-height: 50px;
    padding: 0 10px;
    line-height: 20px;
    white-space: pre-wrap;
}

.front-article__table-th{
    border-bottom:1px solid #001F3D;
    margin: 0;
    padding: 0 10px;
    line-height: 20px;
    min-height: 100px;
}

.front-article__metals{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.front-article__metals-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
    margin-right: 10%;
    margin-top: 10px;
    height: 40%;
    background-color: #D9D9D9;
}

.front-article__metals-title-number{
    font-size: 48px;
    white-space: pre-wrap;
    margin-bottom: 0;
    text-align: center;
    /* padding: 40px; */
    /* border: 5px solid #001F3D ;
    border-radius: 360px;
    border */
}

.front-article__metals-title-text{
    font-size: 14px;
    width: 60%;
    text-align: center;
    margin-bottom: 10px;
    /* padding: 40px; */
    /* border: 5px solid #001F3D ;
    border-radius: 360px;
    border */
}

.front-article__metals-text{
    width: 80%;
}

.front-article__treemap-container{
    width: 1300px;
    height: 500px;
    margin: 0 auto;
}

.front-article__treemap2-container{
    width: 1300px;
    height: 500px;
    margin: 0 auto;
}

.front-article__title{
    font-size: 12px;
    white-space: pre-wrap;
    font-weight: bold;
}

.front-article__text{
    /* font-size: 12px; */
    fill: white;
    text-anchor: middle;
    cursor: pointer;
}

.front-article__rect{
    stroke: white;
    cursor: pointer;
}

.front-article__treemap-tooltip{
    position: absolute;
    width: 500px;
    background-color: white;
    padding: 7px 5px;
    text-align: start;
    font-size: 12px;
    /* font-weight: bold; */
    border-radius: 10px;
    color:#001F3D;
    /* margin-bottom: 100px;
    border: px solid #001F3D; */
}

.front-article__treemap-tooltip2{
    position: absolute;
    width: 200px;
    background-color: white;
    padding: 7px 5px;
    text-align: start;
    font-size: 12px;
    /* font-weight: bold; */
    border-radius: 10px;
    color:#001F3D;
    /* margin-bottom: 100px;
    border: px solid #001F3D; */
}

.front-article__donut-container{
    display: block;
    position: relative;
    width: 700px;
    height: 400px;
    margin: 0 auto;
}

.front-article__price-line-container{
    display: block;
    position: relative;
    width: 800px;
    height: 400px;
    margin: 0 auto;
}

.front-article__price-line-container2{
    display: block;
    position: relative;
    width: 800px;
    height: 400px;
    margin: 0 auto;
}

.front-article__donut{
    stroke-width: 100;
}

.front-article__donut-chart{
    stroke: white;
    stroke-width: 4px;
    cursor: pointer;
}

.front-article__donut-text{
    text-anchor: middle;
    font-size: 16px;
    color: white;
    fill: white;
    cursor: pointer;
}

.front-article__donut-number{
    text-anchor: middle;
    fill: white;
    cursor: pointer;
}

.front-article__donut-total{
    position: absolute;
    font-size: 24px;
    font-weight: bold;
    left: 41%;
    top: 36%;
    color:#001F3D;
    white-space: pre-wrap;
    text-align: center; 
}

.front-article__donut-tooltip{
    width: 200px;
    background-color: #001F3D;
    padding: 7px 5px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    border-radius: 10px;
    color:#001F3D;
    border: 2px white solid;
    color: white;
    z-index: 1;
}

.front-article__exploration{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 30px auto;
    border: 3px #001F3D solid;
}

.front-article__exploration-title{
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    height: 54px;
    text-align: center;
    width: 100%;
    margin: 0 auto;
}

.front-article__exploration-years{
    font-size: 16px; 
    line-height: 24px; 
    text-align: center;
    font-weight: bold;
}

.front-article__exploration-container{
    width: 23%; 
    padding: 15px 2%;
}

.front-article__exploration-container_dev{
    background-color: #D9D9D9;
}

.front-article__section-title{
    font-size: 24px;
}
.front-article__section-subtitle{
    font-size: 22px;
}

.front-article__block-title{
    font-size: 18px;
}
.front-article__block-subtitle{
    font-size: 16px;
}

.front-article__span-italic{
    font-style: italic;
}

.front-article__icons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #D9D9D9;
}

.front-article__icon-image{
    width: 150px;
    height: 150px;
}

.front-article__icon{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    text-align: center
}

.front-article__icon-title{
    text-align: center;
    margin-bottom: -10px;
}


@media screen and (max-width: 1200px) {
    .front-article__graph-title{
        white-space: pre-wrap;
    }

    .front-article__treemap-container{
        width: 900px;
        height: 500px;
    }

    .front-article__treemap2-container{
        width: 900px;
        height: 500px;
    }
    
    .front-article__donut-container{
        width: 900px;
        height: 400px;
    }
    .front-article__donut-total{
        left: 43%;
        top: 35%;
    }
}

@media screen and (max-width: 1000px) {
    .front-article__treemap-container{
        width: 800px;
        height: 500px;
    }
    
    .front-article__donut-container{
        width: 800px;
        height: 400px;
    }

    .front-article__price-line-container{
        width: 800px;
        height: 400px;
    }

    .front-article__treemap2-container{
        width: 800px;
        height: 400px;
    }

    .front-article__graph-title{
        white-space:unset;
    }
}

@media screen and (max-width: 900px) {
.front-article__graph-title{
    margin-bottom: 0
}

.front-article__graph-title-hint{
    display: block;
    text-align: center;
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 20px
}

.front-article__treemap-container{
    width: 600px;
    height: 500px;
}

.front-article__treemap2-container{
    width: 600px;
    height: 500px;
}


.front-article__donut-container{
    width: 350px;
    height: 350px;
}

.front-article__price-line-container{
    width: 600px;
    height: 350px;
}

.front-article__exploration{
        display: flex;
        flex-direction: column;
        border: none
}

.front-article__exploration-container{
        width: 80%; 
        margin: 0 auto;
        padding: 15px 2%;
        border: 1px solid #001F3D;
    }
.front-article__exploration-title{
        height: 100%;
    }

.front-article__donut{
    stroke-width: 70;
}


.front-article__treemap-tooltip{
    border: 2px solid #001F3D;
}

.front-article__donut-total{
    left: 32%;
    top: 34%;
}

.front-article__icon-image{
    width: 120px;
    height: 120px;
}
}


@media screen and (max-width: 650px) {
    .front-article__treemap-container{
        width: 450px;
        height: 950px;
    }

    .front-article__treemap2-container{
        width: 450px;
        height: 950px;
    }

    .front-article__donut-container{
        width: 300px;
        height: 400px;
    }

    .front-article__price-line-container{
        width: 350px;
        height: 350px;
    }

    .front-article__donut-total{
        font-size: 22px;
        left: 31%;
        top: 35%;
    }

    .front-article__donut{
        stroke-width: 60;
    }
    
    .front-article__table{
        font-size: 9px;
    }

    .front-article__table-th{
        padding: 0;
        min-height: 0;
    }    

    .front-article__table-td{
        padding: 0;
        min-height: 0;
        line-height: 12px;
    }

    .front-article__paragraph{
        font-size: 14px;
        line-height: 21px;
    }

    .front-article__list{
        font-size: 14px;
        line-height: 21px;
    }

    .front-article__graph-title{
        font-size: 16px;
        line-height: 30px;
    }

    .front-article__subsubtitle{
        white-space: pre-wrap;
        font-size: 14px;
        line-height: 21px;
    }    

    .front-article__subtitle{
        font-size: 20px;
        line-height: 30px;
    }
    

    .front-article__metals{
        flex-direction: column;
    }

    .front-article__image{
        height: 200px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .front-article__metals-title{
        width: 100%;
        margin:0;
    }

    .front-article__metals-title-text{
        width: 100%;
    }

    .front-article__metals-title-number{
        margin: 0 auto;
    }

    .front-article__metals-text{
        width: 100%;
    }

    .front-article__treemap-tooltip{
        position: absolute;
        width: 300px;
        font-size: 10px;
    }
    
    .front-article__exploration-targets{
        font-size: 14px;
    }

    .front-article__exploration-comments{
        font-size: 14px;
    }

    .front-article__icons{
        flex-wrap: wrap;
    }

    .front-article__icon{
        border-bottom: 1px solid #001F3D;
    }
    
    .front-article__icon_last{
        border-bottom: none;
    }

    .front-article__icon-title{
        text-align: center;
        font-size: 14px;
    }
    
}

@media screen and (max-width: 500px) {    
    .front-article__treemap-container{
        width: 350px;
        height: 700px;
    }
    
    .front-article__treemap2-container{
        width: 350px;
        height: 950px;
    }

    
    .front-article__icons {
        justify-content: space-around;
    }

    .front-article__icon {
        width: 150px;
    }
}


    @media screen and (max-width: 350px) {
        .front-article__treemap-container{
            width: 250px;
            height: 1000px;
        }
            
        .front-article__treemap2-container{
            width: 250px;
            height: 1000px;
        }
        
        .front-article__donut-container{
            width: 250px;
            height: 400px;
        }

        .front-article__price-line-container{
            width: 310px;
            height: 360px;
        }

        .front-article__table{
            font-size: 8px;
        }
        .front-article__treemap-tooltip{
            position: absolute;
            width: 250px;
            font-size: 10px;
        }
    }    