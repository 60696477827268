.contact{
    padding-bottom: 100px;
    background-color: #F5F5F5;
    /* margin-bottom: 10svh; */
}

.contact__field {
    display: flex;
    flex-direction: column;
    /* width: 52.5vw; */
}

.contact__label {
    margin-bottom: 8px;
    font-weight: bold;
    color: #001F3D;
    font-size: 16px;
    text-align: start;
    margin-left: 6px;
}


.contact__topic{
    display:flex;
    flex-direction: column;
    justify-content: end;
    align-items: start;
    background-color: #001F3D;
    height: 30vh;
    text-align: start;
}


.contact__title{
    color: white;
    width: 50%;
    /* padding-top: 10vh; */
    margin-left: 5vw;
    margin-bottom: 4vh;
    line-height: 44px;
    font-size: 38px;
}

.contact__container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 70%;
    margin: 0 auto;
    margin-top: 20px;
}

.contact__subtitle {
    text-align: center; /* Центрирование заголовка */
    color: #001F3D; /* Тот же цвет, что и для заголовков */
    font-size: 24px; /* Размер текста */
    font-weight: bold;
    margin-bottom: 20px; /* Отступ снизу */
    line-height: 1.4; /* Высота строки для удобочитаемости */
    width: 90%; /* Ограничение ширины для компактного вида */
    /* white-space: pre-wrap; */
  }
  

.contact__form{
    position: relative;
    width:80%;
    margin-top: 5px;
}

.contact__form-line{
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    margin-bottom: 20px;
}

.contact__input{
    font-family: Montserrat;
    padding-left:20px;
    color: #001F3D;
    border-radius: 10px;
    border: 2px solid #001F3D;
    width: 22vw;
    height: 40px;
}


.react-tel-input{
    padding-left:20px;
    color: #001F3D;
    width: 22vw;
    height: 40px;
    background-color: white;
    border: 2px solid #001F3D;
    border-radius: 10px;
}

.react-tel-input:focus{
    outline:none;
    border:2px solid #001F3D;
    /* box-shadow:0 0 4px #001F3D; */
}


.selected-flag{
    background-color: white;
    padding: 0;
}

.react-tel-input .selected-flag{
    border: none;
}

.react-tel-input .flag-dropdown{
    border: none;
}

.react-tel-input .form-control{
    width: 98%;
    background: white;
}


.form-control.contact__input-phone {
    font-family: Montserrat;
    padding-left: 37px !important; /* Set your desired padding value */
    border: none;
    padding:0;
    height: 100%;
    background-color: inherit;
}

.contact__input::placeholder {
    color: #001F3D;
    opacity: 0.6;
    font-family: Montserrat;
  }

.contact__input:focus{
    outline:none;
    border:2px solid #001F3D;
    /* box-shadow:0 0 4px #001F3D; */
}

.contact__input-message{
    font-family: Montserrat;
    color: #001F3D;
    padding:20px;
    border-radius: 10px;
    border: 2px solid #001F3D;
    height: 130px;
}

.contact__input-message::placeholder {
    /* position: absolute; */
    color: #001F3D;
    opacity: 0.6;
    font-family: Montserrat;
    top:10px;
    left:10px;
}

.contact__input-message:focus{
    outline:none;
    border:2px solid #001F3D;
    /* box-shadow:0 0 4px #001F3D; */
}


.contact__text{
    color: #001F3D;
}

.contact__note{
    font-size: 12px;
    text-align: start;
    color: #001F3D;
    padding:0;
    margin:0;
    margin-top: 8px;
    margin-left: 12px;
}

.contact__submit {
    cursor: pointer;
    background-color: white;
    font-family: inherit;
    font-weight: bold;
    border-radius: 30px;
    font-size: 14px;
    width: 9vw;
    height: 4vh;
    margin-bottom: 10vh;
    color: #001F3D;
    border: 2px solid #001F3D; /* Solid #001F3D border */
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease; /* Consistent transitions */
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
}

.contact__submit:hover {
    background-color: #F7F7F7; /* Subtle off-white background on hover */
    color: #001F3D; /* Maintain text color */
    border: 2px solid #001F3D; /* Keep border consistent on hover */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08); /* Slightly increased shadow on hover */
    transform: scale(1.05); /* Slight enlargement on hover */
}

.contact__popup{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    top: 40%;
    left: 30%;
    background-color: #001F3D;
    color: white;
    text-align: center;
    font-size: 18px;
    /* font-weight: bold; */
    width: 40%;
    height: 10vw;
}

.contact__popup_hidden{
    position: fixed;
}

.contact__popup:before {
    position: absolute;
    content: " ";
    height: 35px;
    border: 3px solid #001F3D;
    color: white;
    background-color: #001F3D;
    top: -25%;
    right: -5%;
    transform: rotate(45deg);
    z-index: 2;
    cursor: pointer;
  }
  
  .contact__popup:after {
    position: absolute;
    content: " ";
    height: 35px;
    border: 3px solid #001F3D;
    color: white;
    background-color: #001F3D;
    top: -25%;
    right: -5%;
    transform: rotate(-45deg);
    z-index: 2;
    cursor: pointer;
  }

.contact__input::placeholder,
.contact__input-message::placeholder {
    color: #001F3D;
    opacity: 0.6;
    font-family: Montserrat;
}

.contact__input:focus::placeholder,
.contact__input-message:focus::placeholder {
    opacity: 0.3; /* Slightly fade placeholder on focus */
}

.contact__dropdown{
    text-align: start;
}

@media screen and (max-width: 1200px) {
.contact__title{
    width: 60%;
}
}

@media screen and (max-width: 900px) {
    .contact__topic{
        justify-content: center;
        padding-left: 5%;
        padding-top: 65px;
        height: 195px;
    }
    /* .contact__field {
        width: 100%;
    } */


    .contact__subtitle{
        font-size: 22px;
    }
    
    .contact__submit{
        width: 15vw;
    }

    .contact__title{
        margin: 0;
        width: 100%;
    }

    .contact__form{
        width: 100%;
    }
    
    .contact__form-line{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    
    /* .contact__input:focus{
        box-shadow:0 0 1.5px #646060;
    } */

    .contact__input{
        border: 1.5px solid #001F3D;
        width: 25vw;
    }
    .react-tel-input{
        border: 1.5px solid #001F3D;
        width: 25vw;
    }

    .contact__input-message{
        border: 1.5px solid #001F3D;
    }
    .contact__input-message{
        /* border: 2px solid #001F3D; */
        width: 100%;
        height: 200px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    
    
    .contact__text{
        font-size: 22px;
        line-height: 28px;
    }
    
    .contact__note{
        font-size: 12px;
    }
    
    .contact__submit{
        width: 20vw;
        height: 30 px;
    }

    .contact__popup{
        top: 40%;
        left: 20%;
        width: 60%;
        height: 15vw;
    }
    .react-tel-input .form-control{
        width: 96%;
    }

}

@media screen and (max-width: 600px) {
    .contact__topic{
        justify-content: center;
        align-items: center;
        padding-left: 0;
    }
    .contact__label {
        font-size: 14px;
    }

    .contact__title{
        margin: 0;
        width: 90%;
        margin: 0 auto;
        font-size: 2em;
        text-align: center;
    }

    .contact__input{
        width: 100%;
        margin-bottom: 7%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        height: 12vw;
        border-radius: 10px;
    }
    .react-tel-input{
        width: 100%;
        margin-bottom: 7%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        height: 12vw;
        border-radius: 10px;
    }

    /* .contact__input:focus{
        border: 1px solid #646060;
        box-shadow:0 0 2px #646060;
    } */

    .contact__input-message{
        border: 1px solid #001F3D;
    }

    /* .contact__input-message:focus{
        box-shadow:0 0 2px #646060;
    }
     */
    .contact__input-message{
        border-radius: 10px;
    }

    .contact__container{
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 20px
    }

    .contact__container{
        width: 90%;
        height:70%;
    }

    .contact__form-line{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0;
    }
    
    .contact__submit{
        width: 25vw;
        height: 30px;
        border-radius: 15px;
        margin-top: 30px;
    }

    .contact__popup{
        top: 30%;
        left: 10%;
        width: 80%;
        height: 30vw;
    }
    .contact__popup:before {
        height: 25px;
        top: -25%;
        right: -5%;
      }
      
      .contact__popup:after {
        height: 25px;
        top: -25%;
        right: -5%;
      }
}