/* Overall intro section styling */
.intro {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* padding-top: 65px; */
    background-color: #001F3D;
    height: 100%;
}

/* Title styling with smooth transition */
.intro__title {
    margin: 105px auto 40px;
    font-size: 48px;
    line-height: 60px;
    opacity: 0;
    transform: translateY(20px); /* Start slightly below */
    transition: opacity 0.5s ease, transform 0.5s ease;
}

/* Smooth text transition */
.intro__text {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 50vw;
    height: 650px;
    color: white;
    justify-self: center;
    text-align: center; 
    margin: 0;
}

/* When text becomes visible, it smoothly appears */
.intro__text.visible .intro__title {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0s; /* No delay for the title */
}

/* Description text */
.intro__description {
    font-size: 28px;
    line-height: 30px;
    width: 80%;
    text-align: center;
    margin: 0 auto;
    white-space: pre-wrap;
    opacity: 0;
    transform: translateY(20px); /* Start slightly below */
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.intro__text.visible .intro__description {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.3s; /* 100ms delay after the title */
}

/* Button styling */
.intro__button {
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    line-height: 1.5;
    font-weight: bold;
    border-radius: 30px;
    align-self: flex-end;
    border: none;
    width: 190px;
    height: 40px;
    color: #001F3D;
    background-color: white; /* Add a background color */
    cursor: pointer;
    opacity: 0;
    transform: translateY(20px); /* Start slightly below */
    transition: opacity 0.5s ease, transform 0.5s ease, background-color 0.3s ease, box-shadow 0.3s ease; /* Add smooth transitions */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Add light shadow */
}

/* Add hover effect */
.intro__button:hover {
    transform: scale(1.05); /* Slightly increase size on hover */
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2); /* Stronger shadow */
    background-color: #F0F0F0; /* Light gray background on hover */
    color: #001F3D; /* Ensure text color stays the same */
}

/* Active state when the button is fully shown */
.intro__button_active {
    opacity: 1; /* Make button visible */
    transform: translateY(0); /* Return to original position */
    transition: opacity 0.5s ease, transform 0.5s ease; /* Smooth animation */
}


.intro__text.visible {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.6s; /* 100ms delay after the description */
}

/* Mention text styling */
.intro__mention {
    width: 65%;
    margin: 30px auto 10px;
    font-size: 12px;
    white-space: pre-wrap;
    opacity: 0;
    transform: translateY(20px); /* Start slightly below */
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.intro__text.visible .intro__mention {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.9s; /* 100ms delay after the button */
}

/* Picture container with automatic zoom-in effect */
.intro__picture {
    right: 4vw;
    width: 50vw;
    height: 650px;
    margin-top: 65px;
    margin-right: 0;
    background-image: url("../../images/safebox.webp");
    /* background-size: cover; */
    /* background-position: top -30px right -10px; */
    background-position: center; 
    opacity: 0.6;
    background-size: 100%;
    /* transform: scale(0.8); Start smaller */
    transition: background-size 12s ease-in-out, opacity 3s ease-in-out; 
}

/* When the picture becomes visible, it zooms in */
.intro__picture.visible {
    /* transform: scale(1); Zoom to normal size */
    background-size: 110%;
    /* background-position: top -170px right -140px; */
    background-position: center;
    opacity: 0.95;
}

@media screen and (max-width: 1200px) {
    .intro__picture {
        width: 50vw;
        height: 50vw;
    }
    .intro__text {
        height: 50vw;
    }
    .intro__title {
        font-size: 42px;
    }
    .intro__description {
        font-size: 24px;
    }
}

@media screen and (max-width: 1050px) {
    .intro__description {
        font-size: 22px;
    }
    .intro__mention {
        width: 78%;
    }
}

@media screen and (max-width: 1000px) {
    .intro {
        padding-top: 65px;
    }
    .intro__mention {
        margin: 10px auto 10px;
    }
    .intro__description {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 1000px) {
    .intro {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-size: 18px;
        padding-top: 65px;
    }

    .intro__title {
        width: 100%;
        margin-top: 8%;
    }

    .intro__text {
        width: 100%;
        height: 100%;
        margin-left: 0;
    }

    .intro__button {
        margin-top: 30px;
        margin-bottom: 50px;
    }

    .intro__picture {
        width: 100vw;
        height: 80vw;
        margin-top: 0;
    }
    .intro__mention {
        width: 90%;
    }
    .intro__description {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 440px) {
    .intro__description {
        width: 90%;
    }

    .intro__title {
        margin-top: 12%;
        font-size: 38px;
        line-height: 48px;
        margin-bottom: 30px;
    }

    .intro__button {
        width: 80vw;
        border-radius: 10px;
        margin-top: 30px;
    }

    .intro__picture {
        transition: background-size 3s ease-in-out, opacity 3s ease-in-out; 
    }
}


@media screen and (max-width: 400px) {
    .intro__picture {
        height: 350px;
    }
}

@media screen and (max-width: 350px) {
    .intro__picture {
        height: 300px;
    }
}