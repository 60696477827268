.ecology__container{
    display: flex;
    flex-direction: row;
    flex-direction: flex-start;
    width: 100%;    
    margin-top: 50px;
}

.ecology__link{
    text-decoration: none;
}

.ecology__button{
    width: 100%;
    padding: 10px;
    border: none;
    font-family: inherit;
    /* background-color: #4CAF50; */
    background-image: url(../../images/ecology/grass.jpg);
    background-size: cover;
    background-position:left;
    object-fit: contain;
    /* border: 3px solid #4CAF50; */
    font-size: 20px;
    line-height: 1.5;
    /* line-height: 1.5; */
    font-weight: bold;
    /* border-radius: 30px; */
    margin-left:0;
    text-align: center;
    height: 100%;
    cursor: pointer;
}

.ecology__button:hover{
    opacity:0.5;
    /* background-color: #001F3D; */
}

.ecology__text{
    white-space: pre-wrap;
    color: white; 
    text-decoration: none;
    padding: 0;
    margin: 0;
}


@media screen and (max-width: 900px) {

.ecology__container{
    margin-top: 50px;
}

.ecology__button{
    font-size: 16px;
}
}

@media screen and (max-width: 450px) {
.ecology__button{
    width: 95%;
    border-radius: 10px;
    margin: 0 auto;
}
}