.metals{
    width:90%;
    padding: 25px 5% 70px;
    background-color: #001F3D;
}

.metals__info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 40px;
}

.metals__container{
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.metals__title{
    text-align: center;
    color: white;
    line-height: 54px;
    font-size: 44px;
    /* border-bottom: 4px white solid; */
    margin: 0 0 3%;
}

.metals__description{
    /* margin-top: 40px; */
    color: white;
    text-align: start;
    width: 90%;
    /* border:#F5F5F5 2px solid;
    border-radius: 10px; */
    padding: 5px;
    padding-left: 10px;
}

.metals__graphics{
    background-color: #001F3D;
    border-radius: 10px;
}

.metals__paragraph{
    margin-bottom: 40px;
    border-radius: 10px;
    border-left: #4CAF50 solid 6px;
    font-size: 18px;
    padding-left: 5px;
}

.metals__paragraph_bold{
    font-weight: bold;
    color: #4CAF50;
    /* background-color: #F5F5F5; */
}

.metals__link-insights {
    color: #F5F5F5; /* White text for contrast */
    background-color: #001F3D; /* Darker navy background */
    border: #4CAF50 4px solid; /* Green double border */
    border-radius: 10px; /* Rounded corners */
    padding: 12px 24px; /* Padding for button shape */
    font-family: 'Montserrat', sans-serif;
    max-width: 50%;
    font-size: 18px; /* Button font size */
    font-weight: bold; /* Bold text */
    cursor: pointer; /* Pointer cursor to indicate it's clickable */
    text-align: center; /* Center text */
    text-decoration: none; /* Remove any text underline */
    display: inline-block; /* Ensure it's treated as a button */
    transition: background-color 0.3s ease, border-color 0.3s ease, transform 0.2s ease; /* Smooth transition for hover effects */
}

.metals__link-insights:hover {
    border-color: #F5F5F5; /* Lighter green border on hover */
    transform: scale(1.05); /* Slightly increase size to make it pop */
}

.metals__link-insights:active {
    background-color: #001730; /* Return to original color */
    transform: scale(0.98); /* Slightly shrink on press */
    border-color: #4CAF50; /* Return border to original color */
}

.metals__subtitle{
    position: relative;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    /* height: 30px; */
    /* background-color: inherit; */
    /* background-image: url(../../images/offer_copper.jpg);
    background-size: cover;
        opacity:0.5; */
    /* background-image: url("../../images/safebox.webp"); */
    /* background-color: white; */
    padding: 5px;
    /* text-decoration: 1.5px white underline;   */
}


    .metals__link-container{
        background-color: #fff;
    }

    .metals__btbtitle:hover {
        color: #646060;
    }

    .metals__subtitle_down{
        display: none;
    }

@media screen and (max-width: 1100px) {
        .metals__link-insights {
            width: 90%
          }
        }
    

@media screen and (max-width: 900px) {
    .metals__info{
        flex-direction: column;
        justify-content: space-between;
    }
    .metals__description{
        width: 90%;
        padding: 0 5%;
    }

    .metals__container{
        width: 100%;   
        margin-bottom: 50px;
    }

    .metals__link-insights {
        width: 100%
      }
    
    .metals__graphics{
        background-color: #001F3D;
        border:0;
    }
    .metals__subtitle_up{
        display: none;
    }

    .metals__subtitle_down{
        display: block;
    }

}
@media screen and (max-width: 600px) {
    .metals__link-insights{
        text-align: center;
        max-width: 80vw;
        border-radius: 10px;
        padding: 12px 6px;
        font-size: 18px;
        line-height: 1.5;
        /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3) */
    }

    /* .metals__link-insights{
        text-align: center;
        max-width: 80vw;
        border-radius: 10px;
        padding: 12px 6px;
        font-size: 18px;
        line-height: 1.5;
        background-color: #4CAF50;
        opacity: 0.95;
        color: white;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
        border: none;
    } */

    .metals__subtitle{
        font-size: 20px;
        padding-left: 0;
        text-align: center;
    }
}
@media screen and (max-width: 450px) {
    .metals__title{
        font-size: 30px;
        line-height: 36px;
        text-align: center;
    }
    .metals__link-insights{
        border-radius: 10px;
    }

    .metals__subtitle{
        font-size: 20px;
        width: 90%;
        margin: 0 auto;
    }

    .metals__paragraph{
        font-size: 16px;
    }
}