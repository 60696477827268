.ecology{
    padding-top: 130px;
}

.ecology__intro{
    border-left: 2px  solid #4CAF50;
    width: 85%;
    margin: 50px auto 80px;
    padding-left: 20px;
}

.ecology__intro-text{
    text-align: start;
    color: #001F3D;
    padding: 0;
    font-size: 18px;
}

.ecology__title{
    color: #4CAF50;
    font-size: 34px;
    width: 80%;
    margin: 0 auto;
}

.ecology__subtitle{
    color: #001F3D;
    font-size: 32px;
    width: 80%;
    margin: 0 auto;
}

.ecology__block{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 100px 100px;
}

.ecology__image-container{
    width: 50%;
}

.ecology__image{
    width: 320px;
    height: 400px;
}
.ecology__image-phone{
    display: none;
}

.ecology__image_umbrella{
    transform: rotate(-10deg)
}

.ecology__image_recycle{
    transform: rotate(10deg)
}

.ecology__subblock{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
}

.ecology__subblock-number{
    color: #4CAF50;
    font-size: 50px;
    border: 5px solid #4CAF50;
    border-radius: 180px;
    width: 60px;
    margin-bottom: 50px;
}

.ecology__subblock-text{
    color: #001F3D;
    font-size: 18px;
    text-align: start;
    width: 70%;
}

.ecology__program{
    margin: 100px auto;
}

.ecology__program-block{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;   
    align-items: center;
    margin: 50px 80px;
}

.ecology__program-image{
    width: 30%;
    height: 250px;
}

.ecology__program-image-phone{
    display: none;
}


.ecology__program-text{
    color: #001F3D;
    text-align: start;
    width: 30%;
    height: 100%;
    border-bottom: 3px solid #4CAF50;;
}

@media screen and (max-width: 1200px) {
    .ecology__program-block{
        margin: 50px 0;
    }
    .ecology__program-image{
        width: 35%;
        height: 240px;
    }
}

@media screen and (max-width: 900px) {
    .ecology__intro-text{
        font-size: 16px;
    }

    .ecology__subblock-text{
        font-size: 16px;
    }

    .ecology__block{
        margin: 100px 0;
    }
    .ecology__image{
        width: 250px;
        height: 350px;
    }
    /* .ecology__program-block{
        justify-content: space-between;
        margin: 50px 50px;
    } */
    .ecology__title{
        font-size: 26px;
    }
    
    .ecology__subtitle{
        font-size: 24px;
    }
    .ecology__program-image{
        width: 40%;
        height: 230px;
    }
}

@media screen and (max-width: 700px) {
    .ecology__image{
        width: 230px;
        height: 350px;
    }
}

@media screen and (max-width: 600px) {
    /* .ecology__intro{
        border-left: 2px  solid #4CAF50;
        width: 85%;
        margin: 50px auto 80px;
        padding-left: 20px;
    }
    
    .ecology__intro-text{
        text-align: start;
        color: #001F3D;
        padding: 0;
        font-size: 18px;
    }
     */
    .ecology__title{
        font-size: 22px;
        width: 80%;
        margin: 0 auto;
    }
    
    .ecology__subtitle{
        font-size: 20px;
        width: 80%;
        margin: 0 auto;
    }

    .ecology__block{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        margin: 100px 20px;
    }

    /* .ecology__block_recycle{
        justify-content: flex-end;
    }

    .ecology__block_umbrella{
        justify-content: flex-start;
    } */

    .ecology__subblock{
        position: relative;
        /* display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center; */
        width: 100%;
    }

    .ecology__subblock-text{
        color: #001F3D;
        font-size: 16px;
        text-align: start;
        width: 100%;
    }
    
    .ecology__image{
        display: none;
    }

    /* .ecology__image_umbrella{
        transform: rotate(0)
    }
    
    .ecology__image_recycle{
        transform: rotate(0)
    } */
    .ecology__image-container{
        width: 100%;
    }
    .ecology__image{
        width: 320px;
        height: 400px;
    }

    .ecology__image-phone{
        display: block;
        width: 200px;
        height: 200px;
        border-radius: 180px;
        margin: 0 auto;
    }
    
        
    .ecology__program-block{
        flex-direction: column;
        justify-content: space-evenly;   
        align-items: center;
        margin: 50px 20px;
    }

    .ecology__program-text{
        width: 100%;
        border-bottom: 3px solid #4CAF50;
    }

    .ecology__program-image{
        display: none;
    }

    .ecology__program-image-phone{
        display: block;
        width: 100%;
        height: 250px;
    }

    .ecology__subblock-number{
        position: absolute;
        top: -250px;
        left: 5px;
        border: none;
        color:#001F3D
    }

    .ecology__program-text{
        padding-bottom: 30px;
    }
}

@media screen and (max-width: 350px) {
    .ecology__title{
        font-size: 20px;
    }
    
    .ecology__subtitle{
        font-size: 18px;
    }
    .ecology__intro-text{
        font-size: 14px;
    }

    .ecology__subblock-text{
        font-size: 14px;
    }
    .ecology__program-text{
        font-size: 14px;
    }
}
