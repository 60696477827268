.copper__topic{
    display:flex;
    flex-direction: column;
    justify-content: end;
    align-items: start;
    background-color: #001F3D;
    height: 30vh;
    text-align: start;
    margin-bottom: 5vh;
}

.copper__title{
    color: white;
    width: 60%;
    margin-left: 5vw;
    /* margin-bottom: 5vh; */
    line-height: 40px;
    margin-bottom: 50px;
    font-size: 38px;
}

/* .copper__subtitle{
    color: white;
    width: 60%;
    margin-left: 5vw; */
    /* margin-bottom: 5vh; */
    /* line-height: 40px;
    margin-top: 20px;
    font-size: 38px;
} */

.copper__intro{
   margin-top: 20px;
   text-align: start;
   color: #001F3D;
   width: 80%;
   margin: 0 auto 20px;
   white-space: pre-wrap;
}

.copper__intro-list{
    color: #001F3D;
    text-align: start;
    /* width: 90%; */
    margin: 0 10vw;
    display: list-item;
    list-style-type: square;
    list-style-position: outside;
    font-size: 16px;
    line-height: 24px;
}

.copper__intro-title{
    color: #001F3D;
    margin-left: 0;
    text-align: start;
    width: 90%;
    margin: 0 auto;
    margin-top: 10px;
}

.copper__description{
   text-align: start;
   color: #001F3D;
   width: 90%;
   margin: 0 auto;
   margin-top:10px;
   white-space: pre-wrap;
   font-size: 14px;
}

.copper__container{
    display: flex;
    margin: 0 10vw;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}


.copper__list{
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    /* grid-template-rows: 50px 50px ; */
    width: 80%;
    padding: 0;
    margin: 0 auto;
    list-style-type: none;
    text-align: center;
    color: #001F3D;
    border: #001F3D 3px solid;
    border-radius: 40px;
}

.copper__list-title{
    font-size: 24px;
    color:#001F3D;
    margin-bottom: 10px;
}

.copper__list_scale{
    grid-template-columns: 25% 25% 25% 25%;
}

.copper__list_price{
    margin-top: 30px;
    grid-template-columns: 25% 37.5% 37.5%;
}


.copper__bullit{
    font-size: 14px;
}

.copper__bullit_border{
    border-right: #001F3D 2px solid;
}

.copper__bullit-column-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: bold;
    min-height: 30px;
    margin: 0 auto;
}

.copper__bullit-row-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 30px;
    font-weight: bold;
}

.copper__bullit-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 30px;
}

.copper__intro-list-link{
    color:#001F3D;
}

.copper__intro-list-link:hover{
    color: #646060;
}

.copper__bullit-text-price{
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 30px;
}

.copper__rating{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.copper__rating-square{
    /* -webkit-clip-path:polygon(0 0, 100% 0%, 100% 0%, 100% 100%);
    clip-path:polygon(0 0, 100% 0%, 100% 0%, 100% 100%); */
    width:25px;
    height:10px;   
    border: 2px solid #001F3D;
    margin-left: 5px;
    margin-top: 10px;
    /* width: 100px */
}

.copper__rating-square_filled{
    background-color: #001F3D; 
}

@media screen and (max-width: 1024px) {
    .copper__title{
        /* width: 80%; */
        width: 90%;
        margin-bottom: 60px;
        /* margin: 0 auto 4vh; */
    }

    .copper__container {
        /* height: 100%; */
        /* padding: 5% 0%; */
        margin: 0 5%;
    }

    .copper__intro{
        width: 90%;
        margin: 0 5% 20px;
     }
     
     .copper__intro-list{
        width: 90%;
        margin: 0 5% 20px; 
    }
}

@media screen and (max-width: 768px) {
    .copper__topic{
        justify-content: center;
        padding-left: 5%;
        padding-top: 65px;
        height: 195px;
    }
    
    .copper__title{
        margin: 0;
        width: 80%;
    }
    .copper__subtitle{
        margin: 12px 0 auto;  
    }
    .copper__list{
        width: 90%;
    }
}

@media screen and (max-width: 650px) {
    .copper__topic{
        justify-content: center;
        align-items: center;
        padding-left: 0;
    }

    .copper__title{
        width: 90%;
        margin: 0 auto;
        font-size: 2em;
        text-align: center;
    }

    .copper__subtitle{
        width: 90%;
        margin: 12px auto auto;  
        font-size: 2em;
        text-align: center;
    }

    .copper__container{
        margin-top: 20px;
        width: 95%;
        margin-left: 5%;
        padding: 0;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        -ms-verrflow-style: none;
        overflow: - moz-scrollbars-none;
        flex-wrap: nowrap;
        scrollbar-width: none;
        -ms-overflow-style: none; 
    }
    
    .copper__container::-webkit-scrollbar{
        width:0;
    }

    .copper__list{
        margin-top: 40px;
    }
    .copper__bullit{
        font-size: 12px;
    }
    
    .copper__list_price{
        margin-top: 20px;
    }

    .copper__rating{
        display: flex;
        flex-direction: row;
        align-items: center;
        /* justify-content: center; */
    }
    
    .copper__rating-square{
        /* -webkit-clip-path:polygon(0 0, 100% 0%, 100% 0%, 100% 100%);
        clip-path:polygon(0 0, 100% 0%, 100% 0%, 100% 100%); */
        width:15px;
        height:6px;   
        margin-left: 5px;
        margin-top: 10px;
        /* width: 100px */
    }
    
    .copper__rating-square_filled{
        background-color: #001F3D; 
    }
}

@media screen and (max-width: 410px) {
    .copper__bullit{
        font-size: 12px;
    }

    .copper__rating-square{
        width:12.5px;
        height:5px;   
        margin-left: 3px;
    }

    .copper__bullit-row-title{
        font-size: 10px;
    }
    
    .copper__list_price{
        margin-top: 10px;
    }
}


@media screen and (max-width: 350px) {
    .copper__bullit{
        font-size: 8px;
    }
    .copper__rating-square{
        width:10px;
        height:4px;   
        margin-left: 3px;
    }
}