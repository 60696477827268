.partners{
    height: 50vh;
    width: 90%;
    padding: 0 5%;
    background-color: #001F3D;
}

.partners__title{
    padding: 2% 5% 3%;
    color: white;
    text-align: start;
    width: 50%;
    line-height: 54px;
    font-size: 44px;
    /* border-bottom: 4px white solid; */
}

.partners__container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 5%;
}

.partners__logo{
    background-color: white;
    width: 5vw;
    height: 10vh;
}

.partners__name{
    color: white;
}

@media screen and (max-width: 768px) {
    .partners__logo{
        width: 100%;
    }
}

@media screen and (max-width: 450px) {
    .partners{
        padding: 0;
        width: 100%;
    }
    .partners__title{
        width: 90%;
        text-align: center;
        margin: 0;
        margin-bottom: 10vh;
    }
}

