.insights__topic{
    display:flex;
    flex-direction: column;
    justify-content: end;
    align-items: start;
    background-color: #001F3D;
    height: 30vh;
    text-align: start;
    margin-bottom: 5vh;
}

.insights__title{
    color: white;
    /* width: 60%; */
    margin: 0 0 0 5vw;
    padding:0;
    line-height: 44px;
    font-size: 38px;
}

.insights__subtitle{
    color: white;
    /* width: 60%; */
    margin-left: 5vw;
    font-weight: normal;
    font-size: 26px;
}
.insights__page{
    height: 70vh;
    background-color:#001F3D;
}

.insights__container{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    min-height: 100%;
    /* margin-bottom: 100px; */
}
.insights__button-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.insights__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 30%;
    background-color: #fff;
    margin: 0 0 5vh 5vw;
}

.insights__views{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60px;
    height: 35px;
    margin: 0 10vw 5vh 0;
}

.insights__view{
    width: 25px;
    height: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
  }
  
  .insights__view:hover {
    background-color: #E0E0E0; /* Slightly darker background on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Slightly stronger shadow on hover */
    transform: scale(1.1); /* Increased enlargement on hover for more noticeable effect */
    border-color: #001F3D; /* Change border color to #001F3D on hover */

  }
  
.insights__map{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;
}

.insights__more-button {
    cursor: pointer;
    background-color: white;
    font-family: inherit;
    font-weight: bold;
    border-radius: 30px;
    font-size: 14px;
    width: 9vw;
    height: 4vh;
    margin-bottom: 10vh;
    color: #001F3D;
    border: 2px solid #001F3D; /* Solid #001F3D border */
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease; /* Consistent transitions */
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
}

.insights__more-button:hover {
    background-color: #F7F7F7; /* Subtle off-white background on hover */
    color: #001F3D; /* Maintain text color */
    border: 2px solid #001F3D; /* Keep border consistent on hover */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08); /* Slightly increased shadow on hover */
    transform: scale(1.05); /* Slight enlargement on hover */
}


.insights__loading{
    height: 600px;
    font-size: 18px;
    font-weight: bold;
    color:#001F3D;
}

@media screen and (max-width: 1024px) {
    .insights__map{
        width: 100%;
        justify-content: space-between;
    }

    .insights__buttons{
        width: 45%;
    }
    
    .insights__title{
        /* width: 80%; */
        width: 90%;
        margin: 0 auto;
    }

    .insights__container {
        /* height: 100%; */
        /* justify-content: space; */
        padding: 5% 0%;
        padding-top: 0;
        margin: 0 5%;
        min-height: 0%;
    }

    .insights__more-button{
        width: 15vw;
    }
}

@media screen and (max-width: 800px) {
    /* .insights{
        height: 200vh;
    } */
    .insights__button-container{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 20px;    
    }

    .insights__views{
        width: 70px;
    }

    .insights__view{
        width: 25px;
        height: 25px;
        margin-left: 5px;
    }
    

    .insights__more-button{
        width: 150px;   
        height: 30px;
    }

    .insights__topic{
        justify-content: center;
        padding-left: 5%;
        padding-top: 65px;
        height: 195px;
    }

    .insights__title{
        margin: 0;
        width: 80%;
    }
    .insights__subtitle{
        margin: 12px 0 0;  
    }

    .insights__buttons{
        width: 60%;
        margin-left: 0;
    }
}

@media screen and (max-width: 650px) {
    .insights__topic{
        justify-content: center;
        align-items: center;
        padding-left: 0;
        height: 100%;
    }

    .insights__title{
        width: 90%;
        margin: 20px auto 0;
        font-size: 2em;
        text-align: center;
    }

    .insights__subtitle{
        width: 90%;
        margin: 12px auto 20px;  
        font-size: 1.5em;
        text-align: center;
    }

    .insights__map{
        margin: 0 auto;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .insights__buttons{
        width: 100%;
        margin-left: 0;
    }
}

@media screen and (max-width: 320px) {
    /* .insights__map{
        margin: 0 auto;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    } */
    /* .insights__button{
        width: 28vw;
    } */
}