.president{
    height: 60vh;
    width: 72%;
    padding: 3% 14%;
}

.president__title{
    color: #001F3D;
    text-align: start;
    width: 50%;
    border-bottom: #001F3D 4px solid;
}

.president__container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    color: #001F3D;
    margin:0 auto;
}
.president__name{
    font-weight: bold;
    margin:0;
}
.president__profession{
    margin:0;
    margin-top: 1%;
}

.president__photo{
    object-fit: cover;
    max-width: 20vw;
    max-height: 100%;
}

.president__description{
    height: 40vh;
    margin-left: 10vw;
    text-align: start;
}

@media screen and (max-width: 1024px) {
    .president__photo{
        object-fit: cover;
        max-width: 30vw;
        max-height: 100%;
    }
}

@media screen and (max-width: 768px) {
    .president__photo{
        object-fit: cover;
        max-width: 30vw;
        max-height: 100%;
    }
}

@media screen and (max-width: 450px) {
    .president{
        height: 100%;
        width: 90%;
        padding: 0;
        margin: 0 auto;
        /* padding: 3% 14%; */
    }

    .president__photo{
        object-fit: cover;
        max-width: 60vw;
        max-height: 40vh;
        margin-top: 10vh;
        object-fit: cover;
        max-height: 100%;
    }

    .president__container{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    
    .president__description{
        height: 40vh;
        margin-left: 0;
    }
}
