.regulation{
    /* height: 70vh; */
    /* border: 4px solid #646060; */
    width: 72%;
    margin: 50px auto; 
    background-color: #FFFF;
}

.regulation__title{
    color: #001F3D;
    text-align: start;
    width: 100%;
    /* border-bottom: #001F3D 4px solid; */
    line-height: 54px;
    font-size: 44px;
    margin:0;
}

.regulation__subtitle{
    font-size: 18px;
    color: #001F3D;
    text-align: start;
    margin-bottom: 20px;
}

.regulation__container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    color: #001F3D;
    list-style-type: none;
    text-align: start;
    padding:0;
    margin:0;
}

.regulation__item{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 45%;
        color: #001F3D;
}

.regulation__item-title{
        font-size: 20px;
        line-height: 20px;
        margin-top: 45px;
        margin-bottom: 0;
        /* margin-bottom: 10px; */
        height: 40px;
        text-align: center;
}

.regulation__image-content{
        position: relative;
        width: 100%;
        height: 20vw;
        overflow: hidden;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        cursor: pointer;
}

.regulation__image{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    /* margin-bottom: 16px; */
}


.regulation__description{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    bottom: 0;
    left: 0;
    text-wrap: wrap;
    text-align: start;
    font-weight: bold;
    background-color: rgba(13, 14, 32, 0.77);
    width: 90%;
    height: 100%;
    padding: 0 5%;
    transition: all 0.6s ease;
    cursor: pointer;
    transform: translateY(100%);
    color: white;
    z-index: 0;
    text-wrap: wrap;
}

.regulation__paragraph{
    font-size: 16px;
}

.regulation__more-description{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    position: absolute;
    height: 10%;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: rgba(13, 14, 32, 0.77);
    color: #FAFAFA;
    white-space: pre-wrap;
    font-size: 14px;
}

.regulation__image-content:hover .regulation__description{
    transform: translateY(0%)
}

.regulation__image-content:hover .regulation__more-description{
    display: none;
}

@media screen and (max-width: 1200px) {
    .regulation__item{
        width: 47%;
}
    .regulation__image-content{
        height: 30vw;
    }
}

@media screen and (max-width: 950px) {
    .regulation__description_hover{
        transform: translateY(0%)  
    }

    .regulation__more-description{
        display: none;
    }
    
    .regulation{
        width: 90%;
        margin: 5% auto; 
        /* min-height: -webkit-fill-available; */
    }

    .regulation__title{
        font-size: 36px;
    }

    .regulation__subtitle{
        
    }

    .regulation__paragraph{
        font-size: 14px;
    }
}

@media screen and (max-width: 800px) {
    .regulation__image-content{
        height: 35vw;
    }
}

@media screen and (max-width: 620px) {
    .regulation__title{
        margin-top: 12%;
        font-size: 30px;
        line-height: 36px;
        text-align: center;
        margin-bottom: 30px;
    }
    
    .regulation__subtitle{
        text-align: center;
    }

    .regulation__container{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /* flex-wrap: wrap; */
    }
    .regulation__item-title{
        text-align: start;
        font-size: 18px;
    }

    .regulation__list{
        height: 60vw;
    }

    .regulation__image-content{
        height: 50vw;
    }

    .regulation__item{
        width: 100%;
        align-items: flex-start;
    }
}

@media screen and (max-width: 425px) {
    .regulation__image-content{
        height: 210px;
    }
}

@media screen and (max-width: 400px) {
    .regulation__image-content{
        height: 220px;
    }
}

@media screen and (max-width: 360px) {
    .regulation__image-content{
        height: 240px;
    }
}

@media screen and (max-width: 310px) {
    .regulation__image-content{
        height: 270px;
    }
}