.article{
    position: relative;
    height: 100%;
}

.article__table{
    border-top: 1px solid #001F3D;
    table-layout: fixed;
    font-family: inherit;
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    height: 300px;
    font-size: 14px;
}

.article__text{
    white-space: pre-wrap;
}
.article__table-td{
    border-bottom:1px solid #001F3D;
    min-height: 50px;
    padding: 0 10px;
    line-height: 20px;
    font-family: inherit;
    white-space: pre-wrap;
}

.article__table-th{
    border-bottom:1px solid #001F3D;
    margin: 0;
    padding: 0 10px;
    line-height: 20px;
    font-family: inherit;
    min-height: 100px;
}
.article__bold {
    font-weight: bold;
  }

.article__italic {
    font-style: italic;
  }

.article__quote{
    font-size: 20px;
    border-left: #001F3D 3px solid ;
    margin-left: 70px;
    white-space: pre-wrap;
    padding: 10px 20px; 
}   

.article__quote-sign{
font-size: 36px;
}

.article__callout{
    position: relative;
    background-color: #f5f5f5;
    padding: 50px 70px;
}

.article__callout-icon{
    display: none;
    position: absolute;
    left: 1%;
    top: 30%;
}

.article__underline {
    text-decoration: underline;
  }

.article__bullit{
    list-style-type:square;
}
.article__link{
    color: inherit;
}

.article__strikethrough {
    text-decoration: line-through;
  }

.article__code {
    font-family: monospace;
    background-color: #f5f5f5;
    padding: 2px 4px;
    border-radius: 4px;
  }

.article__color-default {
    color: inherit;
  }

.article__topic{
    display:flex;
    flex-direction: column;
    justify-content: end;
    align-items: start;
    background-color: #001F3D;
    height: 30vh;
    text-align: start;
    margin-bottom: 5vh;
}

.article__topic-title{
    color: white;
    /* width: 60%; */
    margin: 0 0 0 5vw;
    padding:0;
    line-height: 44px;
    font-size: 38px;
}

.article__topic-subtitle{
    color: white;
    /* width: 60%; */
    margin-left: 5vw;
    font-weight: normal;
    font-size: 26px;
}

.article__container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-bottom: 100px;
}

.article__title{
    font-size: 24px;
    color: #001F3D;
    margin: 30px auto 0;
}

.article__date{
    margin-top: 0;
    color: #001F3D;
    margin: 0 auto 20px;
}

.article__brief{
    font-size: 16px;
    color: #001F3D;
    width: 80%;
    text-align: start;
    text-wrap: pre;
}

.article__description{
    font-size: 16px;
    color: #001F3D;
    max-width: 80%;
    height: 100%;
    text-wrap: wrap;
    text-align: start;
    overflow-wrap: break-word;
}

.article__picture{
    display: block;
    width: 40vw;
    height: 40vh;
    margin: 0 auto;
}

.article__loading{
    height: 600px;
    font-size: 18px;
    font-weight: bold;
    color: #001F3D;
}

/* Article.css */
.article__progress-bar {
    position: fixed;
    /* header's height*/
    top: 10vh;
    left: 0;
    height: 4px;
    background-color: #001F3D; /* Customize the color */
    z-index: 4;
    /* transition: width 0.1s ease-in-out; */
}

@media screen and (max-width: 1024px) {
    .article__picture{
        width: 60vw;
        margin: 0 auto;
    }
    .article__topic-title{
        /* width: 80%; */
        width: 90%;
        margin: 0 auto  ;
    }
    .article__progress-bar {
        top: 65px;
    }
}

@media screen and (max-width: 900px) {
    .article__topic{
        justify-content: center;
        padding-left: 5%;
        padding-top: 65px;
        height: 195px;
    }
    
    .article__picture{
        width: 100%;
        margin: 0 auto;
    }

    .article__topic-title{
        margin: 0;
        width: 80%;
    }
    .article__topic-subtitle{
        margin: 12px 0 0;  
    }

    .article__description{
        max-width: 95%;
    }
}

@media screen and (max-width: 650px) {
    .article__topic{
        justify-content: center;
        align-items: center;
        padding-left: 0;
        height: 100%;
    }

    .article__topic-title{
        width: 90%;
        margin: 20px auto 0;
        font-size: 2em;
        text-align: center;
    }

    .article__topic-subtitle{
        width: 90%;
        margin: 12px auto 20px;  
        font-size: 1.5em;
        text-align: center;
    }
    .article__quote{
        font-size: 1.2em
    }   

    .article__callout{
        font-size: 1em
    }   
    
    .article__quote-sign{
    font-size: 36px;
    }
    
    .article__callout{
        position: relative;
        background-color: #f5f5f5;
        padding: 50px 70px;
    }
    
    
    .article__table{
        font-size: 10px;
    }

    .article__table-th{
        padding: 0;
        min-height: 0;
    }    

    .article__table-td{
        padding: 0;
        min-height: 0;
        line-height: 12px;
    }
}