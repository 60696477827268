.questions{
    /* height: 90vh; */
    width:72%;
    padding: 10px 14% 200px;
    background-color: #001F3D;
}

.questions__title{
    color: white;
    /* border-bottom: 4px solid white; */
    width: 100%;
    text-align: start;
    margin-bottom:100px;
    line-height: 54px;
    font-size: 44px;
    text-align: center;
}

.questions__container{
    display: flex; 
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.questions__subtitle{
    color: white;
    width: 100%;
    /* margin: 0 auto; */
    text-align: center;
    font-size: 36px;
    line-height: 44px;
}

.questions__link{
    color: white;
    font-weight: bold;
    width: 10vw;
    font-size: 36px;
    line-height: 44px;
}

.questions__link:hover{
    color: #646060;
}

.questions__attention{
    background-color: #fff;
    margin-top: 100px;
    padding: 10px 0;
    min-height: 100%;

}

.questions__subtitle_attention{
    color: #001F3D;
    margin: auto auto;
    text-align: center;
    letter-spacing: 3px;
    margin-bottom: 30px;
}

.questions__paragraph{
    color: #001F3D;
    font-size: 20px;
    /* font-weight: bold; */
    /* font-weight: normal; */
    white-space: pre-wrap;
    width: 90%;
    margin: 10px auto;
    text-align: start;
}
@media screen and (max-width: 1050px) {
    .questions__paragraph{
        width: 80%;
}
}

@media screen and (max-width: 1024px) {
    .questions__subtitle{
        white-space: pre-wrap;
}
}

@media screen and (max-width: 768px) {
    .questions {
        padding: 0 5%;
        width: 90%;
        display: flex; 
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 40px;
    }

    .questions__title {
        width: 100%;
        margin-bottom: 40px;
        font-size: 48px;
        text-align: center;
    }
    
    .questions__subtitle{
        width: 100%;
        font-size: 32px;
        text-align: center;
    }
    
    .questions__link{
        font-size: 30px;
    }

    .questions__attention{
        width: 100%;
    }    

    .questions__paragraph{
        font-size: 18px;
    }
}

@media screen and (max-width: 450px) {
    .questions__title {
        width: 100%;
        margin-bottom: 90px;
        font-size: 30px;
        line-height: 36px;
        text-align: center;
        white-space: pre-line;
    }
    
    .questions__subtitle{
        width: 80%;
        font-size: 24px;
        text-align: center;
    }

    .questions__link{
        font-size: 24px;
    }
    .questions__paragraph{
        font-size: 16px;
    }
}

@media screen and (max-width: 450px) {
    .questions__paragraph{
        font-size: 14px;
    }
}