/* Hover effects for both states */
.common__button:hover {
    transform: scale(1.05); /* Slightly larger enlargement for better interaction feedback */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08); /* Slight increase in shadow but still minimal */
}

/* Inactive button */
.common__button_inactive {
    font-weight: bold;
    color: #001F3D;
    background-color: inherit;
    border: 2px solid #001F3D; /* Solid border for inactive buttons */
    border-radius: 30px;
    transition: all 0.3s ease;
}

.common__button {
    cursor: pointer;
    /* background-color: white; */
    font-family: inherit;
    font-weight: bold;
    border-radius: 30px;
    font-size: 14px;
    width: 9vw;
    height: 30px;
    color:#001F3D;
    border: 2px solid #001F3D; /* Solid border for both states */
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease; /* Added box-shadow transition */
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); /* Very subtle shadow for depth */
}
/* Inactive button hover state */
.common__button_inactive:hover {
    background-color: inherit; /* Subtle off-white background for hover */
    color: #001F3D;
    border-color: #001F3D; /* Ensure consistent border */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08); /* Slight increase in shadow, still minimal */
    transform: scale(1.05); /* Slight enlargement */
}

/* Active button */
.common__button_active {
    background-color: #001F3D;
    color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08); /* Reduced shadow for active state */
    transform: scale(1.03); /* Slightly enlarged active button */
    transition: all 0.3s ease;
    border: 2px solid #001F3D; /* Solid border for active buttons */
    background-color: #001F3D;
}

/* Active button hover state */
.common__button_active:hover {
    background-color: #001F3D; /* Darker blue on hover */
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1); /* Slightly stronger shadow on hover, but still minimal */
    transform: scale(1.07); /* Slightly larger enlargement on hover for active buttons */
}


@media screen and (max-width: 1024px) {
    .common__map{
        width: 100%;
        justify-content: space-between;
    }
    
    .common__button{
        margin-bottom: 0;
        border-radius: 30px;
        font-size: 14px;
        width: 12vw;
        height: 30px;
    }    
}

@media screen and (max-width: 800px) {
    .common__button{
        width: 120px;
    }
}

@media screen and (max-width: 650px) {
    .common__button{
        width: 25vw;
        height: 30px;
        font-size: 14px;
    }
}


@media screen and (max-width: 320px) {
    .common__button{
        width: 28vw;
    }
}