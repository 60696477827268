.types{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 22vw;
    height: 25vw;
    margin: 5vh 0;
    /* filter: brightness(60%); */
}

.types__image{
    position: absolute;
    filter: brightness(40%);
    top:0%;
    left: 0%;
    width: 100%;
    height: 100%;
    /* opacity: 0.8; */
    cursor: pointer;
}

.types__image_hover{
    filter: brightness(60%);
}


.types__info{
    position: absolute;
    top: 0%;
    width:100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    z-index:1;
    cursor: pointer;
}

.types__title{
    margin-top: 20%; 
    text-align: center;
    /* margin-bottom: 30px;  */
    white-space:pre-wrap;  
}

.types__list{
    list-style-type: none;
    text-align: start;
    margin: 0 5%;
    padding: 0;
    margin-bottom: 5%;
}

.types__point{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
}

.types__point-name{
    text-align: start;
    width: 70%;
    padding: 0;
    margin: 0;
    font-size: 16px;
    line-height: 18px;
}

.types__point-price{
    font-size: 16px;
    line-height: 18px;
    margin: 0 auto;
}

.types__rating{
    /* -webkit-clip-path:polygon(0 0, 100% 0%, 100% 0%, 100% 100%);
    clip-path:polygon(0 0, 100% 0%, 100% 0%, 100% 100%); */
    width:25px;
    height:10px;   
    border: 2px solid #fff;
    margin-left: 5px;
    /* width: 100px */
}

.types__rating_filled{
    background-color: #fff; 
}

.types__button{
    border:none;
    border: 2px white solid;
    font-size: 14px;
    /* color: #001F3D; */
    color: #fff;
    font-weight: bold;
    height: 30px;
    width: 50%;
    margin: 0 auto 10px;
    border-radius: 30px;
    background-color:transparent;
    cursor: pointer;
}

.types__button:hover{
    background-color: white;
    color:#001F3D;
    /* -webkit-text-fill-color: transparent;
    -webkit-background-clip: text; */
    /* color: rgba(0, 0, 0, 1); */
}

@media screen and (max-width: 1350px) {
.types{
    width: 25vw;
    scroll-snap-align: start;
    height: 350px;
    cursor:'pointer';
    margin: 0;
}

.types__image{
    position: none;
    width: 100%;
    object-fit:cover;
    user-select: none;
    height: 100%;
}    
}


@media screen and (max-width: 1000px) {
    .types{
        width: 28vw;
    }
    
    .types__point-name{
        font-size: 14px;
        line-height: 16px;
    }

    .types__point-price{
        font-size: 14px;
        line-height: 16px;
    }
    /* .types__rating{
        /* -webkit-clip-path:polygon(0 0, 100% 0%, 100% 0%, 100% 100%);
        clip-path:polygon(0 0, 100% 0%, 100% 0%, 100% 100%); */
        /* width:20px;
        height:7px;
    } */
    .types__image{
        height: 100%
        }    
    .types{
        height: 350px;
    }       
}

@media screen and (max-width: 800px) {
    .types__button{
        width: 70%;
    }
}


@media screen and (max-width: 650px) {
    .types{
    padding: 0;
    height: 100%;
    width: 70vw;
    margin-right: 30px;
    font-size: 24px;
    }

    .types__image{
    position: relative;
    width: 70vw;
    height: 350px
    }   

    .types__title{
        margin-top: 15%; 
        font-size: 20px;
    }

    
    .types__point-name{
        text-align: start;
    }
    .types__button{
        width: 50%;
    }
}