.history{
    background-color: #001F3D;  
    height: 100%;
    width:90%;
    margin: 0 5%;
    margin-bottom: 100px;
}

.history__container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    color: #001F3D;
    height: 100%;
    margin-top: 20px;
}

.history__text{
    margin-top: 4vh;
    margin-left: 4vw;
    margin-right: 0;
    width: 80%;
}

.history__title{
    width: 100%;
    line-height: 54px;
    font-size: 44px;
    color: white;
    text-align: start;
    margin-top: 0;
    /* border-bottom: white 4px solid; */
}

.history__description{
    margin-bottom: 10px;
    width: 90%;
    text-align: start;
    color: white;
    white-space: pre-wrap;
}

.history__photo{
    background-image: url(../../images/offer_copper.jpg);
    background-size: cover;
    width: 20%;
    height: 550px;   
}

@media screen and (max-width: 1200px) {
    .history__photo{
        height: 650px;
    }
}

@media screen and (max-width: 1000px) {
    .history__photo{
        height: 700px;
    }
}

@media screen and (max-width: 850px) {
    .history__photo{
        height: 770px;
    }
}


@media screen and (max-width: 768px) {
    .history__photo{
        width: 15%;
        height: 850px;
    }

    .history__text{
        width: 85%;
    }
}

@media screen and (max-width: 600px) {
    .history__photo{
        height: 1000px;
    }
}

@media screen and (max-width: 510px) {
    .history__container{
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
    }

    .history__title{
        width: 90%;
        margin-bottom: 20px;
        font-size: 40px;
    }
    .history__text{
        width: 100%;
        height: 80%;
        font-size: 14px;
        line-height: 16px;
    }
    .history__photo{
        width: 100%;
        height: 200px
    }
    .history__title{
        text-align: center;
    }
}
