.insights-card{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 20vw;
  height: 340px;
  padding-left: 5vw;
  border-left: 2px solid #646060;
  margin-bottom: 100px;
}

.insights-list{
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 80vw;
  min-height: 40px;
  /* padding-left: 5vw; */
  border-bottom: 2px solid #646060;
  margin-bottom: 30px;
}

.insights-list__container{
  width: 100%;
  margin-bottom: 20px;
}

.insights-card__image{
  height: 120px;
  width: 90%;
  -webkit-filter: grayscale(70%); /* Safari 6.0 - 9.0 */
  filter: grayscale(70%);
  /* -webkit-filter: blur(1px);
  filter: blur(1px); */
}

.insights-list__image{
  height: 100px;
  width: 120px;
  -webkit-filter: grayscale(70%); /* Safari 6.0 - 9.0 */
  filter: grayscale(70%);
  margin-right: 30px;
  align-self: center;
  /* -webkit-filter: blur(1px);
  filter: blur(1px); */
}

.insights-card__title{
  text-align: start;
  color: #001F3D;
  margin-top: 10px;
  margin-bottom: 0;
  min-height: 70px;
}

.insights-list__title{
  text-align: start;
  color: #001F3D;
  margin-top: 10px;
  margin-bottom: 0;
}

.insights-list__first-line{
  display: flex;
  flex-direction: row;
  justify-content:space-between;
}


.insights-card__date{
  text-align: start;
  color: #646060;
  margin: 0;
  font-size: 12px;
}

.insights-list__date{
  text-align: start;
  text-wrap: nowrap;
  color: #646060;
  margin: 0;
  margin-top: 10px;
}

.insights-card__description{
  text-align: start;
  color: #001F3D;
  margin-top: 10px;
  margin-bottom: 40px;
  min-height: 110px;
  width: 90%;
}

.insights-list__description{
  text-align: start;
  color: #001F3D;
  margin-top: 0;
  margin-bottom: 0;
  min-height: 20px;
  width: 80%;
}

.insights-card__link{
  position: absolute;
  bottom: 0;
  text-decoration: underline;
  color: #001F3D;
  cursor: pointer;
}

.insights-list__link{
  margin-top: 0;
  text-align: start;
  text-decoration: underline;
  color: #001F3D;
  cursor: pointer;
}

.insights-list__link:hover{
  color: #646060;
}

.insights-card__link:hover{
  color: #646060;
}

.insights-card__square{
  position: absolute;
  bottom: 0;
  right: 30px;
  background-color: #646060;
  height: 20px;
  width: 20px;
}

.insights-list__date-mobile{
  display:none;
}

@media screen and (max-width: 1024px) {
  .insights-card{
      width: 23vw;
      scroll-snap-align: start;
      height:100%;
      cursor:'pointer';
      margin-top: 0;
      margin-right: 30px;
      padding-left: 3vw;
      margin-bottom: 5vh;
  }

  /* .insights-card__description{
      max-height: 15vh;
} */
  .insights-list{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 90vw;
    min-height: 40px;
    /* padding-left: 5vw; */
    border-bottom: 2px solid #646060;
    margin-bottom: 30px;
  }

  .insights__views{
   margin:0; 
  }

  .insights-card__image{
      position: none;
      width: 100%;
      /* height: 40vh; */
      object-fit:cover;
      user-select: none;
      /* height: 100%; */
  }    
  }
  @media screen and (max-width: 800px) {
      .insights-card{
        width: 22vw;
      }

      .insights-list__date{
        font-size: 12px ;
      }
      
      .insights-card__title{
        font-size: 14px;
      }

      .insights-list__title{
        font-size: 14px ;
      }

      .insights-card__description{
        font-size: 12px;
      }

      .insights-list__link{
        font-size: 12px;
      }

      .insights-list__description{
        font-size: 12px;
        width: 100%;
      }
  }
  
  @media screen and (max-width: 650px) {
      .insights-card{
      padding-left: 5vw;
      /* height: 100%; */
      width: 70%;
      margin: 0 auto;
      margin-bottom: 80px;
      /* margin-right: 30px; */
      /* font-size: 24px; */
      }

      .insights-card__title{
        min-height: 10px;
      }

      .insights-card__description{
        min-height: 30px;
      }

      .insights-card__square{
        right: 0; 
      }
  
      .insights-card__image{
        position: relative;
        width: 100%;
        object-fit: cover;
      }   

      .insights-list{
        min-height: 100px;
        margin-bottom: 0px;
      }

      .insights-list__image{
        height: 80px;
        width: 100px;
        margin-right: 10px;
        margin-top: 5px;
        align-self:flex-start;
      }   

      .insights-list__title{
        font-size: 14px ;
        margin-top: 5px;
      }
      
      .insights-list__link{
        font-size: 12px;
        margin-bottom: 5px;
      }

      .insights-list__date{
        display:none;
      }
      .insights-list__description{
        font-size: 12px;
      }
      .insights-list__container{
        margin-bottom: 10px;
      }

      .insights-list__date-mobile{
        display: block;
        font-size: 10px;
        text-align: start;
        text-wrap: nowrap;
        color: #646060;
        margin: 0;
        margin-bottom: 5px;
      }
  }